import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useEffect, useRef } from "react";

export const useProfile = (session: any) => {
  const { toast } = useToast();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { 
    data: profile, 
    error: profileError, 
    isLoading: loading,
    refetch
  } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) {
        console.log('[useProfile] No session user ID, skipping profile fetch', {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        return null;
      }
      
      console.log('[useProfile] Starting profile fetch:', {
        userId: session.user.id,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        queryState: 'starting'
      });

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .maybeSingle();
        
        if (error) {
          console.error('[useProfile] Error fetching profile:', {
            error,
            userId: session.user.id,
            timestamp: new Date().toISOString(),
            currentPath: window.location.pathname,
            stack: error.stack
          });
          throw error;
        }

        if (!data) {
          console.warn('[useProfile] No profile found for user:', {
            userId: session.user.id,
            timestamp: new Date().toISOString(),
            currentPath: window.location.pathname
          });
          return null;
        }

        console.log('[useProfile] Profile fetch response:', {
          hasData: !!data,
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          queryState: 'completed'
        });
        return data;
      } catch (error: any) {
        console.error('[useProfile] Unexpected error in profile fetch:', {
          error,
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          stack: error.stack
        });
        throw error;
      }
    },
    enabled: !!session?.user?.id,
    retry: 2,
    retryDelay: 1000,
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    gcTime: 1000 * 60 * 10, // Keep unused data in cache for 10 minutes
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (profileError) {
      console.error('[useProfile] Profile fetch error:', {
        error: profileError,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        stack: profileError.stack
      });
      toast({
        title: "Error",
        description: "Failed to load user profile. Please refresh the page.",
        variant: "destructive",
      });
    }
  }, [profileError, toast]);

  // Add timeout for profile loading
  useEffect(() => {
    if (loading && session?.user?.id) {
      timeoutRef.current = setTimeout(() => {
        console.warn('[useProfile] Profile fetch timeout exceeded', {
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        refetch(); // Attempt to refetch on timeout
        toast({
          title: "Warning",
          description: "Profile is taking longer than expected to load. Retrying...",
          variant: "default",
        });
      }, 8000); // 8 second timeout
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading, session?.user?.id, toast, refetch]);

  return { profile, profileError, loading };
};