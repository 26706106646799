import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { FormBusinessProfile } from "../types";
import { useTranslation } from "react-i18next";

interface AddressFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const AddressFields = ({ profile, setProfile }: AddressFieldsProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div className="space-y-2">
        <Label htmlFor="streetAddress">{t('business.form.streetAddress')}</Label>
        <Input
          id="streetAddress"
          value={profile.street_address}
          onChange={(e) => setProfile({ ...profile, street_address: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="city">{t('business.form.city')}</Label>
        <Input
          id="city"
          value={profile.city}
          onChange={(e) => setProfile({ ...profile, city: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="state">{t('business.form.state')}</Label>
        <Input
          id="state"
          value={profile.state}
          onChange={(e) => setProfile({ ...profile, state: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="zipCode">{t('business.form.zipCode')}</Label>
        <Input
          id="zipCode"
          value={profile.zip_code}
          onChange={(e) => setProfile({ ...profile, zip_code: e.target.value })}
        />
      </div>
    </div>
  );
};