import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { FormBusinessProfile } from "../types";
import { useTranslation } from "react-i18next";

interface BasicInfoFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const BasicInfoFields = ({ profile, setProfile }: BasicInfoFieldsProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div className="space-y-2">
        <Label htmlFor="businessName">{t('business.form.businessName')}</Label>
        <Input
          id="businessName"
          value={profile.business_name}
          onChange={(e) => setProfile({ ...profile, business_name: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="businessEmail">{t('business.form.businessEmail')}</Label>
        <Input
          id="businessEmail"
          type="email"
          value={profile.business_email || ''}
          onChange={(e) => setProfile({ ...profile, business_email: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="websiteUrl">{t('business.form.websiteUrl')}</Label>
        <Input
          id="websiteUrl"
          value={profile.website_url || ''}
          onChange={(e) => setProfile({ ...profile, website_url: e.target.value })}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="phoneNumber">{t('business.form.phoneNumber')}</Label>
        <Input
          id="phoneNumber"
          value={profile.phone_number}
          onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
        />
      </div>
    </div>
  );
};