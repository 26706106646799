import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  hint?: string;
  required?: boolean;
}

export const FormField = ({
  id,
  label,
  type,
  value,
  onChange,
  error,
  placeholder,
  disabled,
  hint,
  required
}: FormFieldProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor={id} className="flex items-center">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </Label>
      {hint && <span className="block text-xs text-gray-500">{hint}</span>}
      <Input
        id={id}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={error ? "border-red-500" : ""}
        disabled={disabled}
        required={required}
      />
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
};