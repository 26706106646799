import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface BusinessStatsProps {
  totalResults: number;
  completionStats: {
    complete: number;
    incomplete: number;
    total: number;
  };
}

export const BusinessStats = ({ totalResults, completionStats }: BusinessStatsProps) => {
  return (
    <div className="grid gap-4 md:grid-cols-3">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Businesses</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{totalResults}</div>
          <p className="text-xs text-muted-foreground">
            Active business profiles
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Complete Profiles</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{completionStats.complete}</div>
          <p className="text-xs text-muted-foreground">
            All required fields filled
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Incomplete Profiles</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{completionStats.incomplete}</div>
          <p className="text-xs text-muted-foreground">
            Missing required information
          </p>
        </CardContent>
      </Card>
    </div>
  );
};