import { FormField } from "../../personal-info/FormField";
import { CombinedFormData } from "../../types";

interface AddressFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
}

export const AddressFields = ({ formData, isSubmitting, onChange }: AddressFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <FormField
          id="streetAddress"
          label="Street Address"
          type="text"
          value={formData.streetAddress}
          onChange={(value) => onChange('streetAddress', value)}
          placeholder="Enter your street address"
          disabled={isSubmitting}
          required
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="city"
          label="City"
          type="text"
          value={formData.city}
          onChange={(value) => onChange('city', value)}
          placeholder="Enter your city"
          disabled={isSubmitting}
          required
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="state"
          label="State"
          type="text"
          value={formData.state}
          onChange={(value) => onChange('state', value)}
          placeholder="Enter your state"
          disabled={isSubmitting}
          required
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="zipCode"
          label="ZIP Code"
          type="text"
          value={formData.zipCode}
          onChange={(value) => onChange('zipCode', value)}
          placeholder="Enter your ZIP code"
          disabled={isSubmitting}
          required
        />
      </div>
    </>
  );
};