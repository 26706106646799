export const billing = {
  title: "Billing & Subscription",
  subtitle: "Manage your subscription and payment details",
  
  currentPlan: {
    title: "Current Plan",
    subtitle: "View and manage your subscription details",
    package: "Package",
    monthlyPrice: "Monthly Price",
    nextBillingDate: "Next Billing Date",
    noSubscription: "You don't have an active subscription"
  },

  packages: {
    maps_seo: "Maps & SEO Package",
    basic: "Basic Package",
    premium: "Premium Package"
  },

  billingAddress: {
    title: "Billing Address",
    subtitle: "Your billing information",
    noAddress: "No billing address on file"
  },

  paymentHistory: {
    title: "Payment History",
    subtitle: "View your past payments",
    date: "Date",
    amount: "Amount",
    status: "Status",
    paid: "Paid"
  }
};