import { FormBusinessProfile } from "../types";
import { useTranslation } from "react-i18next";
import { BusinessHoursSchedule, DEFAULT_BUSINESS_HOURS } from "../types/businessHours";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

interface OpeningHoursFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const OpeningHoursFields = ({ profile, setProfile }: OpeningHoursFieldsProps) => {
  const { t } = useTranslation();
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

  // Ensure we have valid opening hours data
  const openingHours = Object.keys(profile.opening_hours_json || {}).length === 0 
    ? DEFAULT_BUSINESS_HOURS 
    : profile.opening_hours_json;

  const handleTimeChange = (day: keyof BusinessHoursSchedule, type: 'open' | 'close', value: string) => {
    const updatedHours = {
      ...openingHours,
      [day]: {
        ...openingHours[day],
        [type]: value || "--:--"
      }
    };

    setProfile({
      ...profile,
      opening_hours_json: updatedHours,
      opening_hours: `${updatedHours[day].open}-${updatedHours[day].close}`
    });
  };

  const formatDayLabel = (day: string) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">{t('business.form.openingHours')}</h3>
      <div className="grid gap-4">
        {days.map((day) => (
          <div key={day} className="grid grid-cols-3 gap-4 items-center">
            <Label className="capitalize">{formatDayLabel(day)}</Label>
            <div className="space-y-2">
              <Label>Opening Time</Label>
              <Input
                type="time"
                value={openingHours[day]?.open || "--:--"}
                onChange={(e) => handleTimeChange(day, 'open', e.target.value)}
                className="w-full"
                placeholder="--:--"
              />
            </div>
            <div className="space-y-2">
              <Label>Closing Time</Label>
              <Input
                type="time"
                value={openingHours[day]?.close || "--:--"}
                onChange={(e) => handleTimeChange(day, 'close', e.target.value)}
                className="w-full"
                placeholder="--:--"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};