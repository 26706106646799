import { useState, useEffect } from 'react';
import { useDebounce } from './useDebounce';
import { supabase } from '@/integrations/supabase/client';
import { PlacePrediction, BusinessDetails } from '@/components/onboarding/steps/business-search/types';

export const useBusinessSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [predictions, setPredictions] = useState<PlacePrediction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<BusinessDetails | null>(null);
  
  const debouncedSearch = useDebounce(searchQuery, 300);

  // Effect to trigger search when debounced value changes
  useEffect(() => {
    if (debouncedSearch) {
      handleSearch(debouncedSearch);
    } else {
      setPredictions([]);
    }
  }, [debouncedSearch]);

  const handleSearch = async (searchTerm?: string) => {
    if (!searchTerm?.trim()) {
      setPredictions([]);
      return;
    }

    setIsLoading(true);
    try {
      console.log('Searching for:', searchTerm);
      const { data, error } = await supabase.functions.invoke('place-search', {
        body: { query: searchTerm }
      });

      if (error) {
        console.error('Search error:', error);
        throw error;
      }
      
      console.log('Search results:', data);
      if (data?.predictions) {
        setPredictions(data.predictions);
      } else {
        console.warn('No predictions in response:', data);
        setPredictions([]);
      }
    } catch (error) {
      console.error('Error searching places:', error);
      setPredictions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlaceDetails = async (placeId: string) => {
    setIsLoading(true);
    try {
      console.log('Getting details for place:', placeId);
      const { data, error } = await supabase.functions.invoke('place-search', {
        body: { placeId }
      });

      if (error) {
        console.error('Place details error:', error);
        throw error;
      }

      console.log('Place details:', data);
      setSelectedPlace(data);
      return data;
    } catch (error) {
      console.error('Error fetching place details:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    searchQuery,
    setSearchQuery,
    predictions,
    isLoading,
    handleSearch,
    getPlaceDetails,
    selectedPlace
  };
};