import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { PersonalInfoForm } from "@/components/account/PersonalInfoForm";
import { useTranslation } from "react-i18next";

const MyAccount = () => {
  const { t } = useTranslation();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 p-4 md:p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t('account.title')}</h1>
              <p className="text-sm text-gray-500">{t('account.subtitle')}</p>
            </div>
            <PersonalInfoForm />
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default MyAccount;