import { FormField } from "../../personal-info/FormField";
import { CombinedFormData } from "../../types";

interface BusinessBasicFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
}

export const BusinessBasicFields = ({ formData, isSubmitting, onChange }: BusinessBasicFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <FormField
          id="businessName"
          label="Business Name"
          type="text"
          value={formData.businessName}
          onChange={(value) => onChange('businessName', value)}
          placeholder="Enter your business name"
          disabled={isSubmitting}
          required
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="businessCategory"
          label="Business Category"
          type="text"
          value={formData.businessCategory}
          onChange={(value) => onChange('businessCategory', value)}
          placeholder="e.g., Restaurant, Retail, Services"
          disabled={isSubmitting}
          required
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="websiteUrl"
          label="Website URL"
          type="url"
          value={formData.websiteUrl}
          onChange={(value) => onChange('websiteUrl', value)}
          placeholder="Enter your website URL"
          disabled={isSubmitting}
        />
      </div>

      <div className="space-y-2">
        <FormField
          id="businessPhoneNumber"
          label="Business Phone Number"
          type="tel"
          value={formData.businessPhoneNumber}
          onChange={(value) => onChange('businessPhoneNumber', value)}
          placeholder="Enter business phone number"
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};