import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { differenceInDays } from "date-fns";
import { Card } from "@/components/ui/card";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

export const GettingStarted = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const dismissMutation = useMutation({
    mutationFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) throw new Error('No session found');

      const { error } = await supabase
        .from('profiles')
        .update({ getting_started_dismissed_at: new Date().toISOString() })
        .eq('id', session.user.id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      toast({
        title: "Getting Started section dismissed",
        description: "You can find helpful resources in the Resources section",
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to dismiss the Getting Started section",
        variant: "destructive",
      });
    },
  });

  // If no profile or created_at date, don't show the section
  if (!profile?.created_at) return null;

  // Calculate days since account creation
  const daysSinceCreation = differenceInDays(
    new Date(),
    new Date(profile.created_at)
  );

  // Don't show if more than 30 days old or if dismissed
  if (daysSinceCreation > 30 || profile.getting_started_dismissed_at) return null;

  const handleDismiss = () => {
    dismissMutation.mutate();
  };

  return (
    <Card className="p-6 mb-8 bg-gradient-to-r from-orange-50 to-orange-100 border-orange-200 relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        onClick={handleDismiss}
      >
        <X className="h-4 w-4" />
      </Button>

      <div className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900">Getting Started with Woobound</h2>
          <p className="mt-2 text-gray-600">
            Welcome to Woobound! We're excited to help you grow your business online. 
            Watch this quick introduction video to learn how to make the most of your account.
          </p>
        </div>

        <div className="aspect-video">
          <iframe
            className="w-full h-full rounded-lg shadow-lg"
            src="https://www.youtube.com/embed/dummy-video-id"
            title="Getting Started with Woobound"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className="text-sm text-gray-500">
          This guide will be available for {30 - daysSinceCreation} more days.
        </div>
      </div>
    </Card>
  );
};