import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ChevronLeft } from "lucide-react";
import { AspectRatio } from "@/components/ui/aspect-ratio";

const PartnerLesson = () => {
  const { lessonId } = useParams();

  const { data: lesson, isLoading } = useQuery({
    queryKey: ['partner_lesson', lessonId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_lessons')
        .select('*')
        .eq('id', lessonId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: otherLessons } = useQuery({
    queryKey: ['partner_lessons'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('partner_lessons')
        .select('*')
        .neq('id', lessonId)
        .order('order_number');

      if (error) throw error;
      return data;
    },
    enabled: !!lessonId,
  });

  if (isLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-4 md:p-8">
            <div className="animate-pulse">
              <div className="h-8 w-64 bg-gray-200 rounded mb-4"></div>
              <div className="h-4 w-96 bg-gray-200 rounded mb-8"></div>
            </div>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  if (!lesson) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-4 md:p-8">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-gray-900 mb-2">Lesson Not Found</h1>
              <p className="text-gray-500 mb-4">The lesson you're looking for doesn't exist.</p>
              <Button asChild>
                <Link to="/partner">Back to Partner Portal</Link>
              </Button>
            </div>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <div className="flex-1">
          <main className="p-4 md:p-8">
            <div className="max-w-6xl mx-auto space-y-8">
              <div>
                <Button
                  variant="ghost"
                  className="mb-4"
                  asChild
                >
                  <Link to="/partner" className="flex items-center gap-2">
                    <ChevronLeft className="h-4 w-4" />
                    Back to Partner Portal
                  </Link>
                </Button>

                <h1 className="text-2xl font-semibold text-gray-900 mb-2">{lesson.title}</h1>
                {lesson.description && (
                  <p className="text-gray-500 mb-6">{lesson.description}</p>
                )}
              </div>

              <div className="grid gap-8 md:grid-cols-3">
                <div className="md:col-span-2">
                  {lesson.video_url && (
                    <Card className="overflow-hidden">
                      <AspectRatio ratio={16 / 9}>
                        <iframe
                          src={lesson.video_url}
                          title={lesson.title}
                          className="w-full h-full"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </AspectRatio>
                    </Card>
                  )}

                  {lesson.document_url && (
                    <div className="mt-6">
                      <h2 className="text-lg font-semibold text-gray-900 mb-4">Additional Resources</h2>
                      <Card className="p-4">
                        <a
                          href={lesson.document_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary hover:text-primary/80"
                        >
                          Download Resource Document
                        </a>
                      </Card>
                    </div>
                  )}
                </div>

                <div>
                  <h2 className="text-lg font-semibold text-gray-900 mb-4">Other Lessons</h2>
                  <div className="space-y-4">
                    {otherLessons?.map((otherLesson) => (
                      <Card key={otherLesson.id} className="p-4">
                        <Link
                          to={`/partner/lessons/${otherLesson.id}`}
                          className="block hover:text-primary"
                        >
                          <h3 className="font-medium mb-1">{otherLesson.title}</h3>
                          {otherLesson.description && (
                            <p className="text-sm text-gray-500 line-clamp-2">
                              {otherLesson.description}
                            </p>
                          )}
                        </Link>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default PartnerLesson;