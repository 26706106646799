export const resources = {
  title: "Learning Resources",
  subtitle: "Explore our curated collection of articles and guides",
  
  filters: {
    all: "All Resources",
    premium: "Premium Content",
    free: "Free Content"
  },

  card: {
    readTime: "{{minutes}} min read",
    startReading: "Start Reading",
    readAgain: "Read Again",
    completed: "Completed",
    premium: "Premium"
  },

  empty: {
    title: "No resources available",
    description: "Check back later for new content"
  }
};