export const googleTrends = {
  title: "Google Trends Analysis",
  subtitle: 'Analyzing trends for "nail salon" over the last 12 months',

  howToRead: {
    title: "How to Read This Data",
    description: "These charts show the relative interest in your business category over time:",
    points: {
      peakPopularity: "A value of 100 represents peak popularity for the term",
      halfPopularity: "A value of 50 means the term is half as popular",
      noData: "A score of 0 means insufficient data",
      localOpportunities: "Compare national and state trends to identify local opportunities"
    }
  },
  
  nationalTrends: {
    title: "National Trends",
    subtitle: "National Interest Trends",
    description: 'Showing nationwide interest in "nail salon"'
  },
  
  stateTrends: {
    title: "State Trends",
    subtitle: "Texas Interest Trends",
    description: 'Showing interest in "nail salon" within Texas'
  }
};
