import { useParams, Navigate, useNavigate } from "react-router-dom";
import { SignupFlowSteps } from "@/components/onboarding/SignupFlowSteps";
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { LoadingSpinner } from "@/components/routing/LoadingSpinner";
import { PaymentSuccess } from "@/components/onboarding/steps/checkout/PaymentSuccess";

export const ChatSignupPage = () => {
  const { packageType } = useParams<{ packageType: string }>();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTempUserData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');

        if (email) {
          const { data: tempData, error } = await supabase
            .from('temp_user_data')
            .select('*')
            .eq('email', email)
            .eq('completed', false)
            .single();

          if (error) {
            console.error('Error fetching temp user data:', error);
          } else if (tempData) {
            setFormData({
              email: tempData.email,
              firstName: tempData.first_name,
              lastName: tempData.last_name,
              phoneNumber: tempData.phone_number,
              businessName: tempData.business_name,
              streetAddress: tempData.street_address,
              city: tempData.city,
              state: tempData.state,
              zipCode: tempData.zip_code,
            });
            setStep(3);
          }
        }
      } catch (error) {
        console.error('Error in checkTempUserData:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkTempUserData();
  }, []);

  const handleCombinedInfoComplete = async (data: any) => {
    console.log("Step 1 completed, storing form data:", data);
    setFormData(data);
    setStep(2);
  };

  const handleCheckoutComplete = async () => {
    setShowPaymentSuccess(true);
  };

  const handleGoogleConnect = () => {
    window.open('https://woobound.digitalsero.app/clients/onboarding/c50a97dd-5503-4c9b-89a3-1f8c795a0b95', '_blank');
    setIsGoogleConnected(true);
  };

  const handleGoogleConnectComplete = async (connected: boolean) => {
    try {
      console.log("Starting user account creation...");
      
      // First, create the user account
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error("Failed to create user account");

      console.log("User account created, waiting for session...");

      // Wait for the user to be fully authenticated
      const { data: { user }, error: sessionError } = await supabase.auth.getUser();
      if (sessionError) throw sessionError;
      if (!user) throw new Error("No authenticated user found");

      console.log("Creating business profile...");

      // Create business profile
      const { error: businessError } = await supabase
        .from('business_profiles')
        .insert({
          user_id: user.id,
          business_name: formData.businessName,
          website_url: formData.websiteUrl || null,
          street_address: formData.streetAddress,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          category: formData.businessCategory,
          phone_number: formData.businessPhoneNumber,
          business_email: formData.email
        });

      if (businessError) {
        console.error("Business profile creation error:", businessError);
        throw businessError;
      }

      console.log("Creating onboarding session...");

      // Create onboarding session
      const { error: sessionError2 } = await supabase
        .from('onboarding_sessions')
        .insert({
          user_id: user.id,
          package_type: packageType,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          business_name: formData.businessName,
          website_url: formData.websiteUrl,
          business_category: formData.businessCategory,
          street_address: formData.streetAddress,
          google_business_connected: connected,
          completed: true,
          checkout_completed: true
        });

      if (sessionError2) {
        console.error("Onboarding session creation error:", sessionError2);
        throw sessionError2;
      }

      console.log("All operations completed successfully, navigating to dashboard...");
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error completing onboarding:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  if (!packageType || !["maps_seo", "maps_website_seo"].includes(packageType)) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (showPaymentSuccess) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <div className="w-full max-w-2xl">
          <PaymentSuccess
            onGoogleConnect={handleGoogleConnect}
            onSkip={() => handleGoogleConnectComplete(false)}
            isConnected={isGoogleConnected}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <SignupFlowSteps
          step={step}
          personalInfo={formData}
          businessInfo={formData}
          onCombinedInfoComplete={handleCombinedInfoComplete}
          onCheckoutComplete={handleCheckoutComplete}
          onGoogleConnectComplete={handleGoogleConnectComplete}
        />
      </div>
    </div>
  );
};

export default ChatSignupPage;