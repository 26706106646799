import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { BillingInfoFields } from "./payment/BillingInfoFields";
import { CardFields } from "./payment/CardFields";
import { PaymentFormHeader } from "./payment/PaymentFormHeader";
import { Input } from "@/components/ui/input";
import { usePartnerCode } from "./payment/usePartnerCode";
import { usePaymentSubmission } from "@/hooks/usePaymentSubmission";

interface PaymentFormProps {
  packageType: "maps_seo" | "maps_website_seo";
  amount: number;
  onSuccess: () => void;
  isSignupFlow?: boolean;
  customerInfo?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
}

export const PaymentForm = ({ 
  packageType, 
  amount, 
  onSuccess, 
  isSignupFlow = false,
  customerInfo 
}: PaymentFormProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingInfo, setBillingInfo] = useState({
    firstName: customerInfo?.firstName || "",
    lastName: customerInfo?.lastName || "",
    email: customerInfo?.email || "",
    street: customerInfo?.street || "",
    city: customerInfo?.city || "",
    state: customerInfo?.state || "",
    zipCode: customerInfo?.zipCode || "",
  });

  const { 
    partnerCode, 
    discountedAmount, 
    isValidatingCode, 
    handlePartnerCodeChange,
    validatePartnerCode 
  } = usePartnerCode(amount);

  const { handlePayment, isProcessing } = usePaymentSubmission(
    packageType,
    discountedAmount || amount,
    onSuccess,
    isSignupFlow
  );

  const handleBillingInfoChange = (field: string, value: string) => {
    setBillingInfo(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    await handlePayment({
      cardNumber,
      expiryDate,
      cvv,
      billingInfo
    });
  };

  return (
    <Card className="p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <PaymentFormHeader 
          title="Payment Information"
          subtitle="Please provide your payment details"
        />
        
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-lg font-medium">Monthly Price:</span>
            <span className="text-lg">${amount}</span>
          </div>

          <div className="space-y-2">
            <label htmlFor="partnerCode" className="text-sm font-medium">
              Partner Code
            </label>
            <div className="flex gap-2">
              <Input
                id="partnerCode"
                value={partnerCode}
                onChange={(e) => handlePartnerCodeChange(e.target.value)}
                placeholder="Enter partner code"
                className="flex-1"
                disabled={isValidatingCode}
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => validatePartnerCode(partnerCode)}
                disabled={isValidatingCode || !partnerCode}
              >
                {isValidatingCode ? "Validating..." : "Apply"}
              </Button>
            </div>
          </div>

          {discountedAmount !== null && (
            <div className="p-4 bg-green-50 rounded-md space-y-2">
              <div className="flex items-center justify-between text-green-600">
                <span className="font-medium">Partner Code Applied!</span>
                <span>${amount - discountedAmount} discount</span>
              </div>
              <div className="flex items-center justify-between text-green-700 font-medium">
                <span>New Monthly Total:</span>
                <span>${discountedAmount}</span>
              </div>
            </div>
          )}
        </div>
        
        <BillingInfoFields
          billingInfo={billingInfo}
          onChange={handleBillingInfoChange}
          isSubmitting={isProcessing}
        />
        
        <CardFields
          cardNumber={cardNumber}
          expiryDate={expiryDate}
          cvv={cvv}
          onChange={{
            setCardNumber,
            setExpiryDate,
            setCvv
          }}
          isSubmitting={isProcessing}
        />

        <Button 
          type="submit" 
          className="w-full"
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : `Pay $${discountedAmount || amount} per month`}
        </Button>
      </form>
    </Card>
  );
};