import { BusinessSearchInput } from "../business-search/BusinessSearchInput";
import { BusinessDetails } from "../business-search/types";
import { CombinedFormData } from "../types";
import { BusinessBasicFields } from "./business-search/BusinessBasicFields";
import { AddressFields } from "./business-search/AddressFields";
import { parseAddressComponents } from "./business-search/AddressParser";

interface BusinessInfoFieldsProps {
  formData: CombinedFormData;
  isSubmitting: boolean;
  onChange: (field: keyof CombinedFormData, value: string) => void;
}

export const BusinessInfoFields = ({ formData, isSubmitting, onChange }: BusinessInfoFieldsProps) => {
  const handleBusinessSelect = (details: BusinessDetails) => {
    if (!details) {
      console.error('No business details provided');
      return;
    }
    
    console.log('Processing business details:', details);

    // Update business name, website, and phone
    onChange('businessName', details.name);
    onChange('websiteUrl', details.website || '');
    onChange('businessPhoneNumber', details.formatted_phone_number || '');

    // Try to determine business category from types
    if (details.types && details.types.length > 0) {
      const category = details.types[0]
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      onChange('businessCategory', category);
    }

    // Parse and update address fields
    const { streetAddress, city, state, zipCode } = parseAddressComponents(details);
    onChange('streetAddress', streetAddress);
    onChange('city', city);
    onChange('state', state);
    onChange('zipCode', zipCode);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold pt-6">Business Information</h2>
      <div className="grid md:grid-cols-2 gap-4">
        <BusinessSearchInput onBusinessSelect={handleBusinessSelect} />
        
        <BusinessBasicFields
          formData={formData}
          isSubmitting={isSubmitting}
          onChange={onChange}
        />

        <AddressFields
          formData={formData}
          isSubmitting={isSubmitting}
          onChange={onChange}
        />
      </div>
    </div>
  );
};