import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { BusinessWithProfile } from "../types";

interface BusinessEditFormProps {
  business: BusinessWithProfile;
  onSuccess: () => void;
}

export const BusinessEditForm = ({ business, onSuccess }: BusinessEditFormProps) => {
  const { toast } = useToast();
  const [formData, setFormData] = useState(business);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { error: updateError } = await supabase
        .from('business_profiles')
        .update({
          business_name: formData.business_name,
          business_email: formData.business_email,
          website_url: formData.website_url,
          phone_number: formData.phone_number,
          street_address: formData.street_address,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zip_code,
          category: formData.category,
          opening_hours: formData.opening_hours,
          short_description: formData.short_description,
          long_description: formData.long_description,
          facebook_url: formData.facebook_url,
          instagram_url: formData.instagram_url,
          business_services: formData.business_services,
          admin_notes: formData.admin_notes,
          dashboard_url: formData.dashboard_url, // Added dashboard_url field
        })
        .eq('id', business.id);

      if (updateError) throw updateError;

      // Log the admin activity
      const { error: activityError } = await supabase
        .from('admin_activities')
        .insert({
          admin_id: (await supabase.auth.getUser()).data.user?.id,
          activity_type: 'update_business',
          business_id: business.id,
          description: 'Updated business profile information',
        });

      if (activityError) throw activityError;

      toast({
        title: "Success",
        description: "Business profile updated successfully",
      });
      
      onSuccess();
    } catch (error: any) {
      console.error('Error updating business:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (field: keyof BusinessWithProfile, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="business_name">Business Name</Label>
          <Input
            id="business_name"
            value={formData.business_name}
            onChange={(e) => handleInputChange('business_name', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="business_email">Business Email</Label>
          <Input
            id="business_email"
            type="email"
            value={formData.business_email || ''}
            onChange={(e) => handleInputChange('business_email', e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="website_url">Website URL</Label>
          <Input
            id="website_url"
            type="url"
            value={formData.website_url || ''}
            onChange={(e) => handleInputChange('website_url', e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="phone_number">Phone Number</Label>
          <Input
            id="phone_number"
            value={formData.phone_number}
            onChange={(e) => handleInputChange('phone_number', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="street_address">Street Address</Label>
          <Input
            id="street_address"
            value={formData.street_address}
            onChange={(e) => handleInputChange('street_address', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="city">City</Label>
          <Input
            id="city"
            value={formData.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="state">State</Label>
          <Input
            id="state"
            value={formData.state}
            onChange={(e) => handleInputChange('state', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="zip_code">ZIP Code</Label>
          <Input
            id="zip_code"
            value={formData.zip_code}
            onChange={(e) => handleInputChange('zip_code', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="category">Category</Label>
          <Input
            id="category"
            value={formData.category}
            onChange={(e) => handleInputChange('category', e.target.value)}
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="opening_hours">Opening Hours</Label>
          <Input
            id="opening_hours"
            value={formData.opening_hours}
            onChange={(e) => handleInputChange('opening_hours', e.target.value)}
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="dashboard_url">Dashboard URL</Label>
        <Input
          id="dashboard_url"
          value={formData.dashboard_url || ''}
          onChange={(e) => handleInputChange('dashboard_url', e.target.value)}
          placeholder="https://www.local-marketing-reports.com/location-dashboard/..."
          className="w-full"
        />
        <p className="text-sm text-gray-500">
          Enter the URL for this business's marketing reports dashboard
        </p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="short_description">Short Description</Label>
        <Input
          id="short_description"
          value={formData.short_description || ''}
          onChange={(e) => handleInputChange('short_description', e.target.value)}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="long_description">Long Description</Label>
        <Textarea
          id="long_description"
          value={formData.long_description || ''}
          onChange={(e) => handleInputChange('long_description', e.target.value)}
          className="min-h-[100px]"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="facebook_url">Facebook URL</Label>
        <Input
          id="facebook_url"
          type="url"
          value={formData.facebook_url || ''}
          onChange={(e) => handleInputChange('facebook_url', e.target.value)}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="instagram_url">Instagram URL</Label>
        <Input
          id="instagram_url"
          type="url"
          value={formData.instagram_url || ''}
          onChange={(e) => handleInputChange('instagram_url', e.target.value)}
        />
      </div>

      <div className="flex justify-end gap-4">
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : "Save Changes"}
        </Button>
      </div>
    </form>
  );
};
