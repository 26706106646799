import { Circle, Check } from "lucide-react";
import { CombinedInfoStep } from "./steps/CombinedInfoStep";
import { CheckoutStep } from "./steps/CheckoutStep";
import { GoogleConnectStep } from "./steps/GoogleConnectStep";
import { WooboundLogo } from "@/components/shared/WooboundLogo";
import { CombinedFormData } from "./steps/types";

interface SignupFlowStepsProps {
  step: number;
  personalInfo: Partial<CombinedFormData>;
  businessInfo: Partial<CombinedFormData>;
  onCombinedInfoComplete: (data: CombinedFormData) => void;
  onCheckoutComplete: () => void;
  onGoogleConnectComplete: (connected: boolean) => void;
}

const StepIndicator = ({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) => {
  const steps = [
    { number: 1, label: "Business Information" },
    { number: 2, label: "Payment" },
    { number: 3, label: "Connect Google" }
  ];

  return (
    <div className="flex items-center justify-between mb-8">
      {steps.map((step, index) => {
        const isCompleted = step.number < currentStep;
        const isCurrent = step.number === currentStep;

        return (
          <div key={step.number} className="flex items-center flex-1">
            <div className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center shrink-0 ${
                  isCompleted
                    ? "bg-[#e0933c]"
                    : isCurrent
                    ? "bg-[#e0933c]/20 text-[#e0933c]"
                    : "bg-gray-200"
                }`}
              >
                {isCompleted ? (
                  <Check className="w-4 h-4 text-white" />
                ) : (
                  <span className="text-sm">{step.number}</span>
                )}
              </div>
              <span
                className={`text-sm whitespace-nowrap ${
                  isCurrent ? "text-[#e0933c]" : "text-gray-500"
                }`}
              >
                {step.label}
              </span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`h-0.5 w-full mx-4 ${
                  step.number < currentStep ? "bg-[#e0933c]" : "bg-gray-200"
                }`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const SignupFlowSteps = ({
  step,
  personalInfo,
  businessInfo,
  onCombinedInfoComplete,
  onCheckoutComplete,
  onGoogleConnectComplete,
}: SignupFlowStepsProps) => {
  // Initialize with default values for required fields
  const defaultFormData: CombinedFormData = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    businessPhoneNumber: '',
    businessName: '',
    businessCategory: '',
    websiteUrl: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
  };

  const combinedFormData = {
    ...defaultFormData,
    ...personalInfo,
    ...businessInfo,
  };

  return (
    <div className="space-y-4">
      <WooboundLogo />
      <StepIndicator currentStep={step} totalSteps={3} />
      
      {step === 1 && (
        <CombinedInfoStep 
          onComplete={onCombinedInfoComplete}
          formData={combinedFormData}
        />
      )}
      {step === 2 && (
        <CheckoutStep 
          onComplete={onCheckoutComplete} 
          formData={combinedFormData}
        />
      )}
      {step === 3 && (
        <GoogleConnectStep onComplete={onGoogleConnectComplete} />
      )}
    </div>
  );
};