import { BusinessDetails } from "../../business-search/types";

export const parseAddressComponents = (details: BusinessDetails) => {
  if (!details.address_components || details.address_components.length === 0) {
    console.warn('No address components found, falling back to formatted address');
    return parseFormattedAddress(details.formatted_address);
  }

  console.log('Processing address components:', details.address_components);
  
  let streetNumber = '';
  let route = '';
  let unit = '';
  let city = '';
  let state = '';
  let zipCode = '';

  // First pass: collect all components
  details.address_components.forEach(component => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      route = component.long_name;
    }
    if (component.types.includes('subpremise')) {
      unit = component.long_name;
    }
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      zipCode = component.long_name;
    }
  });

  // Build street address
  let streetAddress = '';
  if (streetNumber && route) {
    streetAddress = `${streetNumber} ${route}`;
    if (unit) {
      streetAddress += ` ${unit}`;
    }
  } else {
    // Fallback to first line of formatted address
    streetAddress = details.formatted_address.split(',')[0];
  }

  console.log('Parsed address components:', {
    streetAddress,
    city,
    state,
    zipCode
  });

  return { streetAddress, city, state, zipCode };
};

export const parseFormattedAddress = (formattedAddress: string) => {
  const addressParts = formattedAddress.split(',');
  if (addressParts.length >= 3) {
    const streetAddress = addressParts[0].trim();
    const city = addressParts[1].trim();
    const stateZip = addressParts[2].trim().split(' ');
    
    return {
      streetAddress,
      city,
      state: stateZip[0].trim(),
      zipCode: stateZip.length >= 2 ? stateZip[1].trim() : ''
    };
  }
  
  return {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: ''
  };
};