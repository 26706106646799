import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { Card } from "@/components/ui/card";
import { Video } from "lucide-react";
import { Navigate } from "react-router-dom";

interface Video {
  id: string;
  title: string;
  description: string | null;
  video_url: string;
  language: string;
}

const Videos = () => {
  const { t, i18n } = useTranslation();
  
  const { data: session } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data } = await supabase.auth.getSession();
      return data.session;
    },
  });

  const { data: videos, isLoading } = useQuery({
    queryKey: ['videos', i18n.language],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('videos')
        .select('*')
        .eq('language', i18n.language)
        .order('created_at', { ascending: true });

      if (error) throw error;
      return data as Video[];
    },
  });

  if (!session) {
    return <Navigate to="/login" />;
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-gray-50">
        <AppSidebar />
        <div className="flex-1 p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                <Video className="h-5 w-5 text-[#F97316]" />
                {t('videos.title')}
              </h2>
              <p className="text-sm text-gray-500 mb-6">{t('videos.subtitle')}</p>

              {isLoading ? (
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {[1, 2, 3].map((i) => (
                    <Card key={i} className="p-6 animate-pulse">
                      <div className="aspect-video bg-gray-200 mb-4"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                      <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {videos?.map((video) => (
                    <Card key={video.id} className="overflow-hidden">
                      <div className="aspect-video">
                        <iframe
                          src={video.video_url}
                          title={video.title}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="w-full h-full"
                        />
                      </div>
                      <div className="p-4">
                        <h3 className="font-semibold text-gray-900 mb-2">{video.title}</h3>
                        {video.description && (
                          <p className="text-sm text-gray-500">{video.description}</p>
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Videos;