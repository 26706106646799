import { BusinessProfile } from "@/components/business/types";

export interface CompletionStatus {
  percentage: number;
  missingFields: Array<{
    name: string;
    field: keyof BusinessProfile;
  }>;
  completed: number;
  total: number;
}

export const calculateProfileCompletion = (profile: BusinessProfile): CompletionStatus => {
  console.log('Calculating completion for profile:', profile);

  if (!profile) {
    console.error('Profile is undefined or null');
    return {
      percentage: 0,
      missingFields: [],
      completed: 0,
      total: 0
    };
  }

  const requiredFields = [
    { name: 'Business Name', field: 'business_name' as const },
    { name: 'Website', field: 'website_url' as const },
    { name: 'Street Address', field: 'street_address' as const },
    { name: 'City', field: 'city' as const },
    { name: 'State', field: 'state' as const },
    { name: 'ZIP Code', field: 'zip_code' as const },
    { name: 'Category', field: 'category' as const },
    { name: 'Phone Number', field: 'phone_number' as const },
    { name: 'Opening Hours', field: 'opening_hours' as const },
    { name: 'Business Email', field: 'business_email' as const },
    { name: 'Short Description', field: 'short_description' as const },
    { name: 'Long Description', field: 'long_description' as const },
    { name: 'Facebook URL', field: 'facebook_url' as const },
    { name: 'Instagram URL', field: 'instagram_url' as const },
    { name: 'Business Services', field: 'business_services' as const },
    { name: 'Logo URL', field: 'logo_url' as const },
    { name: 'Square Logo URL', field: 'square_logo_url' as const },
    { name: 'Primary Image URL', field: 'primary_image_url' as const },
    { name: 'Opening Date', field: 'opening_date' as const },
    { name: 'SEO Campaign Goal', field: 'seo_campaign_goal' as const },
    { name: 'Desired Keywords', field: 'desired_keywords' as const },
    { name: 'Payment Methods', field: 'payment_methods' as const },
    { name: 'Promotions', field: 'promotions' as const },
    { name: 'Opening Hours JSON', field: 'opening_hours_json' as const },
    { name: 'Admin Notes', field: 'admin_notes' as const }
  ];

  const isFieldEmpty = (field: keyof BusinessProfile): boolean => {
    const value = profile[field];
     // Commenting out debug log
   // console.log(`Checking field ${field}:`, value);
    
    if (value === null || value === undefined) {
      return true;
    }

    // Handle arrays
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    
    // Handle objects (like opening_hours_json)
    if (typeof value === 'object') {
      return !value || Object.keys(value).length === 0;
    }
    
    // Handle strings
    if (typeof value === 'string') {
      return value.trim() === '';
    }

    return false;
  };

  const missingFields = requiredFields.filter(({ field }) => isFieldEmpty(field));

   // Commenting out debug log
  //console.log('Missing fields:', missingFields);

  const total = requiredFields.length;
  const completed = total - missingFields.length;
  const percentage = Math.round((completed / total) * 100);

   // Commenting out debug log
  //console.log(`Completion stats: ${completed}/${total} = ${percentage}%`);

  return {
    percentage,
    missingFields,
    completed,
    total
  };
};
