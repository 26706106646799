import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { LoadingSpinner } from "./LoadingSpinner";

interface ProtectedRouteProps {
  children: ReactNode;
  session: any;
  requireAdmin?: boolean;
  requirePartner?: boolean;
}

export const ProtectedRoute = ({ 
  children, 
  session,
  requireAdmin = false,
  requirePartner = false 
}: ProtectedRouteProps) => {
  console.log("[ProtectedRoute] Evaluating route protection", {
    hasSession: !!session,
    userId: session?.user?.id,
    timestamp: new Date().toISOString(),
    currentPath: window.location.pathname,
    requireAdmin,
    requirePartner
  });
  
  const { data: profile, isLoading: profileLoading } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) {
        console.log("[ProtectedRoute] No session user ID found", {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        return null;
      }
      
      console.log("[ProtectedRoute] Fetching profile", {
        userId: session.user.id,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .maybeSingle();
        
      if (error) {
        console.error("[ProtectedRoute] Error fetching profile:", {
          error,
          userId: session.user.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname,
          stack: error.stack
        });
        throw error;
      }
      
      return data;
    },
    enabled: !!session?.user?.id,
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    retry: 1,
    retryDelay: 1000,
  });

  // Show loading state while profile is loading for an authenticated user
  if (profileLoading && session) {
    console.log("[ProtectedRoute] Loading state", {
      timestamp: new Date().toISOString(),
      currentPath: window.location.pathname,
      hasSession: !!session,
      profileLoading
    });
    return <LoadingSpinner />;
  }

  // If there's no session and we're not already on the login page, redirect to login
  if (!session) {
    console.log("[ProtectedRoute] No session, redirecting to login", {
      timestamp: new Date().toISOString(),
      currentPath: window.location.pathname
    });
    return <Navigate to="/login" replace />;
  }

  // Check admin requirement
  if (requireAdmin && !profile?.is_admin) {
    console.log("[ProtectedRoute] Admin access required but user is not admin", {
      userId: session.user.id,
      timestamp: new Date().toISOString(),
      currentPath: window.location.pathname
    });
    return <Navigate to="/dashboard" replace />;
  }

  // Check partner requirement
  if (requirePartner && !(profile?.is_partner || profile?.is_admin)) {
    console.log("[ProtectedRoute] Partner access required but user is not partner", {
      userId: session.user.id,
      timestamp: new Date().toISOString(),
      currentPath: window.location.pathname
    });
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};