import { useCallback } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { useBusinessSearch } from "./hooks/useBusinessSearch";
import { BusinessSearchBar } from "./business-table/BusinessSearchBar";
import { BusinessList } from "./business-table/BusinessList";
import { BusinessFilters } from "./business-table/BusinessFilters";
import { BusinessStats } from "./business-table/BusinessStats";

export const BusinessTable = () => {
  const { toast } = useToast();
  const {
    businesses,
    isLoading,
    error,
    searchQuery,
    setSearchQuery,
    searchField,
    setSearchField,
    filterOptions,
    setFilterOptions,
    handleSearch,
    totalResults,
    completionStats,
    refetch
  } = useBusinessSearch();

  const handleSearchSubmit = useCallback(() => {
    if (!searchQuery.trim()) {
      toast({
        title: "Search query required",
        description: "Please enter a search term",
        variant: "destructive",
      });
      return;
    }
    handleSearch();
  }, [searchQuery, handleSearch, toast]);

  if (error) {
    return (
      <Card className="mt-6">
        <CardHeader className="pb-4">
          <CardTitle className="text-red-500">Error Loading Data</CardTitle>
          <CardDescription>
            There was an error loading the business profiles. Please try again later.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Business Profiles</CardTitle>
          <CardDescription>
            Search and manage business profiles
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <BusinessSearchBar
              searchQuery={searchQuery}
              searchField={searchField}
              onSearchQueryChange={setSearchQuery}
              onSearchFieldChange={setSearchField}
              onSearch={handleSearchSubmit}
              isLoading={isLoading}
            />

            <BusinessFilters
              filterOptions={filterOptions}
              onFilterChange={setFilterOptions}
            />

            <BusinessStats
              totalResults={totalResults}
              completionStats={completionStats}
            />

            <BusinessList
              businesses={businesses}
              isLoading={isLoading}
              onUpdate={refetch}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};