import { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { BusinessWithProfile, SearchField, FilterOptions } from "../business-table/types";

export const useBusinessSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState<SearchField>("business_name");
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    completionStatus: 'all',
    category: 'all',
    dateRange: { from: null, to: null }
  });

  const buildSearchQuery = useCallback(() => {
    let query = supabase
      .from('business_profiles')
      .select(`
        *,
        profiles:user_id (
          email,
          first_name,
          last_name
        )
      `);

    // Apply search filters
    if (searchQuery) {
      switch (searchField) {
        case 'email':
          query = query.ilike('profiles.email', `%${searchQuery}%`);
          break;
        case 'business_name':
          query = query.ilike('business_name', `%${searchQuery}%`);
          break;
        case 'phone_number':
          query = query.ilike('phone_number', `%${searchQuery}%`);
          break;
        case 'zip_code':
          query = query.ilike('zip_code', `%${searchQuery}%`);
          break;
        case 'category':
          query = query.ilike('category', `%${searchQuery}%`);
          break;
        default:
          query = query.ilike(searchField, `%${searchQuery}%`);
      }
    }

    // Apply category filter
    if (filterOptions.category !== 'all') {
      query = query.eq('category', filterOptions.category);
    }

    // Apply date range filter
    if (filterOptions.dateRange.from) {
      query = query.gte('created_at', filterOptions.dateRange.from.toISOString());
    }
    if (filterOptions.dateRange.to) {
      query = query.lte('created_at', filterOptions.dateRange.to.toISOString());
    }

    return query;
  }, [searchQuery, searchField, filterOptions]);

  const { data: businesses, isLoading, error, refetch } = useQuery({
    queryKey: ['business_profiles', searchQuery, searchField, filterOptions],
    queryFn: async () => {
      const query = buildSearchQuery();
      const { data, error: queryError } = await query;

      if (queryError) {
        console.error('Error fetching business profiles:', queryError);
        throw queryError;
      }

      return data as BusinessWithProfile[];
    },
  });

  const calculateCompletionStats = useCallback((businesses: BusinessWithProfile[]) => {
    if (!businesses?.length) return { complete: 0, incomplete: 0, total: 0 };
    
    const complete = businesses.filter(b => {
      const requiredFields = [
        'business_name',
        'website_url',
        'street_address',
        'city',
        'state',
        'zip_code',
        'category',
        'phone_number',
        'opening_hours'
      ];
      return requiredFields.every(field => Boolean(b[field]));
    }).length;

    return {
      complete,
      incomplete: businesses.length - complete,
      total: businesses.length
    };
  }, []);

  const stats = calculateCompletionStats(businesses || []);

  return {
    businesses,
    isLoading,
    error,
    searchQuery,
    setSearchQuery,
    searchField,
    setSearchField,
    filterOptions,
    setFilterOptions,
    handleSearch: refetch,
    totalResults: businesses?.length || 0,
    completionStats: stats,
    refetch // Added this line to expose the refetch function
  };
};