interface PaymentFormHeaderProps {
  title: string;
  subtitle: string;
}

export const PaymentFormHeader = ({ title, subtitle }: PaymentFormHeaderProps) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-1">{title}</h3>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  );
};