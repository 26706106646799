export const googleTrends = {
  title: "Análisis de Google Trends",
  subtitle: 'Analizando las tendencias para "salón de uñas" en los últimos 12 meses',

  howToRead: {
    title: "Cómo Leer Estos Datos",
    description: "Estos gráficos muestran el interés relativo en tu categoría de negocio a lo largo del tiempo:",
    points: {
      peakPopularity: "Un valor de 100 representa el pico de popularidad para el término",
      halfPopularity: "Un valor de 50 significa que el término es la mitad de popular",
      noData: "Un puntaje de 0 significa que no hay suficientes datos",
      localOpportunities: "Compara las tendencias nacionales y estatales para identificar oportunidades locales"
    }
  },
  
  nationalTrends: {
    title: "Tendencias Nacionales",
    subtitle: "Tendencias de Interés Nacional",
    description: 'Mostrando el interés nacional en "salón de uñas"'
  },
  
  stateTrends: {
    title: "Tendencias Estatales",
    subtitle: "Tendencias de Interés en Texas",
    description: 'Mostrando el interés en "salón de uñas" dentro de Texas'
  }
}
