import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { calculateProfileCompletion } from "@/utils/businessProfile";
import { BusinessProfile } from "./types";

interface ProfileCompletionCardProps {
  profile: BusinessProfile;
  className?: string;
}

export function ProfileCompletionCard({ profile, className }: ProfileCompletionCardProps) {
  const navigate = useNavigate();
  const status = calculateProfileCompletion(profile);

  return (
    <Card className={className}>
      <CardHeader className="pb-2">
        <CardTitle className="text-sm font-medium">Profile Completion</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-bold">{status.percentage}%</span>
            {status.percentage === 100 ? (
              <CheckCircle2 className="h-5 w-5 text-green-500" />
            ) : (
              <AlertCircle className="h-5 w-5 text-amber-500" />
            )}
          </div>
          
          <Progress value={status.percentage} className="h-2" />
          
          {status.missingFields.length > 0 && (
            <div className="space-y-2">
              <p className="text-sm text-muted-foreground">
                Complete your profile to increase your chances of being found by customers!
              </p>
              <div className="space-y-1">
                {status.missingFields.map(({ name }) => (
                  <div key={name} className="text-sm text-red-500 flex items-center gap-2">
                    <AlertCircle className="h-4 w-4" />
                    {name} missing
                  </div>
                ))}
              </div>
              <Button 
                variant="default" 
                className="w-full"
                onClick={() => navigate('/business-profile')}
              >
                Complete Profile
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}