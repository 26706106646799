import { useState } from 'react';
import { BusinessInfoFields } from './combined-info/BusinessInfoFields';
import { PersonalInfoFields } from './combined-info/PersonalInfoFields';
import { CombinedFormData, CombinedInfoStepProps } from './types';

export const CombinedInfoStep = ({ onComplete, formData: initialData }: CombinedInfoStepProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<CombinedFormData>({
    email: initialData?.email || '',
    firstName: initialData?.firstName || '',
    lastName: initialData?.lastName || '',
    password: initialData?.password || '',
    phoneNumber: initialData?.phoneNumber || '',
    businessPhoneNumber: initialData?.businessPhoneNumber || '',
    businessName: initialData?.businessName || '',
    businessCategory: initialData?.businessCategory || '',
    websiteUrl: initialData?.websiteUrl || '',
    streetAddress: initialData?.streetAddress || '',
    city: initialData?.city || '',
    state: initialData?.state || '',
    zipCode: initialData?.zipCode || '',
  });

  const handleFieldChange = (field: keyof CombinedFormData, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onComplete(formData);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <PersonalInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
      />
      <BusinessInfoFields
        formData={formData}
        isSubmitting={isSubmitting}
        onChange={handleFieldChange}
      />
      <button 
        type="submit" 
        className="btn btn-primary w-full"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Processing...' : 'Next'}
      </button>
    </form>
  );
};