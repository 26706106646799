import { FormBusinessProfile } from "../types";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface AdditionalInfoFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const AdditionalInfoFields = ({ profile, setProfile }: AdditionalInfoFieldsProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Additional Information</h3>
      
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="business_services">Business Services</Label>
          <Input
            id="business_services"
            value={profile.business_services?.join(', ') || ''}
            onChange={(e) => setProfile({ 
              ...profile, 
              business_services: e.target.value.split(',').map(s => s.trim()).filter(s => s)
            })}
            placeholder="Enter services separated by commas"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="payment_methods">Payment Methods</Label>
          <Input
            id="payment_methods"
            value={profile.payment_methods?.join(', ') || ''}
            onChange={(e) => setProfile({ 
              ...profile, 
              payment_methods: e.target.value.split(',').map(p => p.trim()).filter(p => p)
            })}
            placeholder="Enter payment methods separated by commas"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="opening_date">Opening Date</Label>
          <Input
            id="opening_date"
            type="date"
            value={profile.opening_date || ''}
            onChange={(e) => setProfile({ ...profile, opening_date: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="promotions">Current Promotions</Label>
          <Input
            id="promotions"
            value={profile.promotions || ''}
            onChange={(e) => setProfile({ ...profile, promotions: e.target.value })}
            placeholder="Enter current promotions"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="facebook_url">Facebook URL</Label>
          <Input
            id="facebook_url"
            type="url"
            value={profile.facebook_url || ''}
            onChange={(e) => setProfile({ ...profile, facebook_url: e.target.value })}
            placeholder="https://facebook.com/yourbusiness"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="instagram_url">Instagram URL</Label>
          <Input
            id="instagram_url"
            type="url"
            value={profile.instagram_url || ''}
            onChange={(e) => setProfile({ ...profile, instagram_url: e.target.value })}
            placeholder="https://instagram.com/yourbusiness"
          />
        </div>
      </div>
    </div>
  );
};