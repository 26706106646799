import { Button } from "@/components/ui/button";
import { ExternalLink, CheckCircle2 } from "lucide-react";
import { Card } from "@/components/ui/card";

interface PaymentSuccessProps {
  onGoogleConnect: () => void;
  onSkip: () => void;
  isConnected: boolean;
}

export const PaymentSuccess = ({ onGoogleConnect, onSkip, isConnected }: PaymentSuccessProps) => {
  return (
    <div className="space-y-8">
      <div className="text-center space-y-2">
        <div className="flex justify-center mb-4">
          <CheckCircle2 className="h-12 w-12 text-green-500" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900">Payment Successful!</h2>
        <p className="text-gray-600">
          Thank you for your payment. Your subscription has been activated.
        </p>
      </div>

      <Card className="p-6 bg-orange-50 border-orange-200">
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-900">Final Step: Connect Google Business Profile</h3>
          <p className="text-gray-600">
            To start optimizing your brand online, we need access to your Google Business Profile. 
            This is the last step to complete your setup.
          </p>
          
          <div className="space-y-4">
            <Button 
              onClick={onGoogleConnect}
              className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90 flex items-center justify-center"
            >
              Connect Google Business Profile
              <ExternalLink className="ml-2 h-4 w-4" />
            </Button>

            {isConnected && (
              <Button 
                onClick={onSkip}
                className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
              >
                Continue to Dashboard
              </Button>
            )}

            <Button 
              variant="outline"
              onClick={onSkip}
              className="w-full"
            >
              Skip for now
            </Button>

            {!isConnected && (
              <p className="text-sm text-gray-500 text-center">
                You can always connect your Google Business Profile later from your dashboard.
              </p>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};