import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Pencil, Trash2, Eye } from "lucide-react";

interface ArticleListProps {
  articles: any[];
  onEdit: (article: any) => void;
  onDelete: (id: string) => void;
  onPublish: (id: string) => void;
}

export const ArticleList = ({ articles, onEdit, onDelete, onPublish }: ArticleListProps) => {
  return (
    <Card className="p-6">
      <h2 className="text-lg font-semibold mb-4">Articles List</h2>
      <div className="relative overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Min Age</TableHead>
              <TableHead>Premium</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {articles?.map((article) => (
              <TableRow key={article.id}>
                <TableCell className="font-medium">{article.title}</TableCell>
                <TableCell>{article.category}</TableCell>
                <TableCell>{article.status}</TableCell>
                <TableCell>{article.min_account_age_months} months</TableCell>
                <TableCell>{article.premium ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {article.status === 'draft' && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => onPublish(article.id)}
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                    )}
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => onEdit(article)}
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => onDelete(article.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};