import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Building2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { BusinessFormFields } from "./BusinessFormFields";
import { BusinessProfile, BusinessHoursSchedule, FormBusinessProfile } from "./types";
import { useTranslation } from "react-i18next";
import { ImageGallery } from "./ImageGallery";

const defaultProfile: FormBusinessProfile = {
  id: "",
  user_id: null,
  business_name: "",
  website_url: null,
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  category: "",
  phone_number: "",
  opening_hours: "9:00-17:00",
  opening_hours_json: {
    monday: { open: "09:00", close: "17:00" },
    tuesday: { open: "09:00", close: "17:00" },
    wednesday: { open: "09:00", close: "17:00" },
    thursday: { open: "09:00", close: "17:00" },
    friday: { open: "09:00", close: "17:00" },
    saturday: { open: "09:00", close: "17:00" },
    sunday: { open: "09:00", close: "17:00" }
  },
  seo_campaign_goal: "",
  desired_keywords: null,
  payment_methods: null,
  opening_date: null,
  promotions: null,
  business_email: null,
  short_description: null,
  long_description: null,
  facebook_url: null,
  instagram_url: null,
  business_services: null,
  created_at: null,
  updated_at: null,
  admin_notes: null,
  logo_url: null,
  square_logo_url: null,
  primary_image_url: null,
};

const BusinessInfoForm = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<FormBusinessProfile>(defaultProfile);
  const { toast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          toast({
            title: t('business.form.error'),
            description: t('You must be logged in to view this page'),
            variant: "destructive",
          });
          return;
        }

        const { data: existingProfile, error: fetchError } = await supabase
          .from('business_profiles')
          .select('*')
          .eq('user_id', user.id)
          .maybeSingle();

        if (fetchError) throw fetchError;

        if (!existingProfile) {
          const newProfile = {
            ...defaultProfile,
            user_id: user.id,
          };

          const { data: createdProfile, error: insertError } = await supabase
            .from('business_profiles')
            .insert([{
              ...newProfile,
              opening_hours_json: JSON.stringify(newProfile.opening_hours_json)
            }])
            .select()
            .single();

          if (insertError) throw insertError;
          
          setProfile({
            ...defaultProfile,
            ...createdProfile,
            opening_hours_json: typeof createdProfile.opening_hours_json === 'string' ? 
              JSON.parse(createdProfile.opening_hours_json) : 
              createdProfile.opening_hours_json as unknown as BusinessHoursSchedule
          });
        } else {
          setProfile({
            ...defaultProfile,
            ...existingProfile,
            opening_hours_json: typeof existingProfile.opening_hours_json === 'string' ? 
              JSON.parse(existingProfile.opening_hours_json) : 
              existingProfile.opening_hours_json as unknown as BusinessHoursSchedule
          });
        }
      } catch (error: any) {
        console.error('Error fetching profile:', error);
        toast({
          title: t('business.form.error'),
          description: error.message,
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [toast, t]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error(t('You must be logged in to update your profile'));

      const updatedProfile = {
        ...profile,
        opening_hours_json: JSON.stringify(profile.opening_hours_json)
      };

      const { error } = await supabase
        .from('business_profiles')
        .update(updatedProfile)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: t('business.form.success'),
      });
    } catch (error: any) {
      console.error('Error updating profile:', error);
      toast({
        title: t('business.form.error'),
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpdate = (field: string, value: string | null) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (loading) return <div>{t('Loading...')}</div>;

  return (
    <Card className="p-8 bg-white shadow-sm border border-gray-100 w-full">
      <div className="flex items-center gap-3 mb-8">
        <div className="p-3 rounded-full bg-primary/10">
          <Building2 className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{t('business.title')}</h2>
          <p className="text-sm text-gray-500">{t('business.subtitle')}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <BusinessFormFields 
          profile={profile} 
          setProfile={(newProfile: FormBusinessProfile) => {
            setProfile(newProfile);
          }} 
        />
        
        <ImageGallery
          businessId={profile.id}
          logoUrl={profile.logo_url}
          squareLogoUrl={profile.square_logo_url}
          primaryImageUrl={profile.primary_image_url}
          onUpdate={handleImageUpdate}
        />

        <div className="pt-4">
          <Button 
            type="submit" 
            className="w-full md:w-auto bg-primary hover:bg-primary/90"
            disabled={loading}
          >
            {loading ? t('business.form.saving') : t('business.form.saveChanges')}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default BusinessInfoForm;