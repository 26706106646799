export const account = {
  title: "My Account",
  subtitle: "Manage your personal information and preferences",

  personalInfo: {
    title: "Personal Information",
    subtitle: "Update your personal details",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email Address",
    emailHelp: "Contact support to update your email address",
    phoneNumber: "Phone Number",
    role: "Role",
    saveChanges: "Save Changes",
    saving: "Saving...",
    success: "Your information has been updated successfully"
  },

  security: {
    title: "Security",
    subtitle: "Manage your password and account security",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
    updatePassword: "Update Password",
    updating: "Updating...",
    success: "Your password has been updated successfully"
  },

  preferences: {
    title: "Preferences",
    subtitle: "Customize your account settings",
    language: "Language",
    notifications: "Email Notifications",
    timezone: "Timezone"
  }
};