import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Image, Upload } from "lucide-react";

interface ImageGalleryProps {
  businessId: string;
  logoUrl: string | null;
  squareLogoUrl: string | null;
  primaryImageUrl: string | null;
  onUpdate: (field: string, value: string | null) => void;
}

export const ImageGallery = ({ 
  businessId, 
  logoUrl, 
  squareLogoUrl, 
  primaryImageUrl,
  onUpdate 
}: ImageGalleryProps) => {
  const [uploading, setUploading] = useState<Record<string, boolean>>({});
  const { toast } = useToast();

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'logo' | 'square_logo' | 'primary_image'
  ) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      // Validate file type
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if (!['jpg', 'jpeg', 'png'].includes(fileExt || '')) {
        toast({
          title: "Invalid file type",
          description: "Please upload only JPG, JPEG or PNG files",
          variant: "destructive",
        });
        return;
      }

      setUploading(prev => ({ ...prev, [type]: true }));

      // Generate unique file path
      const filePath = `${businessId}/${type}_${Math.random().toString(36).slice(2)}.${fileExt}`;

      // Upload file to Supabase Storage
      const { error: uploadError, data } = await supabase.storage
        .from('business_images')
        .upload(filePath, file, {
          upsert: true,
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('business_images')
        .getPublicUrl(filePath);

      // Update business profile with new URL
      const fieldMapping = {
        logo: 'logo_url',
        square_logo: 'square_logo_url',
        primary_image: 'primary_image_url'
      };

      const { error: updateError } = await supabase
        .from('business_profiles')
        .update({ [fieldMapping[type]]: publicUrl })
        .eq('id', businessId);

      if (updateError) throw updateError;

      onUpdate(fieldMapping[type], publicUrl);

      toast({
        title: "Success",
        description: "Image uploaded successfully",
      });
    } catch (error: any) {
      console.error('Error uploading file:', error);
      toast({
        title: "Error uploading file",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(prev => ({ ...prev, [type]: false }));
    }
  };

  const ImageUploadCard = ({ 
    title, 
    type,
    currentUrl 
  }: { 
    title: string; 
    type: 'logo' | 'square_logo' | 'primary_image';
    currentUrl: string | null;
  }) => (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-lg">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {currentUrl && (
            <div className="relative w-full aspect-video">
              <img
                src={currentUrl}
                alt={title}
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          )}
          <div className="flex items-center gap-4">
            <Button
              variant="outline"
              className="w-full"
              disabled={uploading[type]}
              onClick={() => document.getElementById(`${type}-upload`)?.click()}
            >
              {uploading[type] ? (
                "Uploading..."
              ) : (
                <>
                  <Upload className="w-4 h-4 mr-2" />
                  Upload {currentUrl ? "New" : ""} Image
                </>
              )}
            </Button>
            <input
              id={`${type}-upload`}
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => handleFileUpload(e, type)}
              className="hidden"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold mb-4">Business Images</h2>
        <p className="text-sm text-gray-500 mb-6">
          Upload your business logo and images. Supported formats: JPG, JPEG, PNG
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <ImageUploadCard
          title="Business Logo"
          type="logo"
          currentUrl={logoUrl}
        />
        <ImageUploadCard
          title="Square Logo"
          type="square_logo"
          currentUrl={squareLogoUrl}
        />
        <ImageUploadCard
          title="Primary Header Image"
          type="primary_image"
          currentUrl={primaryImageUrl}
        />
      </div>
    </div>
  );
};