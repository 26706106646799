import { useEffect, useState } from "react";
import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "@/components/ui/skeleton";
import { AlertCircle, Info } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TrendChart } from "@/components/trends/TrendChart";
import { TrendsGuide } from "@/components/trends/TrendsGuide";

interface TrendData {
  date: string;
  value: number;
}

interface BusinessProfileData {
  category: string;
  state: string;
  last_trends_check?: string | null;
}

export default function GoogleTrends() {
  const { toast } = useToast();
  const [nationalTrendData, setNationalTrendData] = useState<TrendData[]>([]);
  const [stateTrendData, setStateTrendData] = useState<TrendData[]>([]);
  const [lastCategory, setLastCategory] = useState<string | null>(null);

  const { data: businessProfile, isLoading: profileLoading, error: profileError } = useQuery({
    queryKey: ['business-profile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data, error } = await supabase
        .from('business_profiles')
        .select('category, state, last_trends_check')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) throw error;
      return data as BusinessProfileData;
    },
  });

  useEffect(() => {
    const fetchTrends = async () => {
      if (!businessProfile?.category || !businessProfile?.state) return;

      try {
        // Update last checked timestamp
        const { error: updateError } = await supabase
          .from('business_profiles')
          .update({ last_trends_check: new Date().toISOString() })
          .eq('category', businessProfile.category);

        if (updateError) {
          console.error('Error updating last_trends_check:', updateError);
        }

        // Fetch national trends
        const { data: nationalData, error: nationalError } = await supabase.functions.invoke('google-trends', {
          body: { 
            keyword: businessProfile.category,
            geo: 'US'
          }
        });

        if (nationalError) throw nationalError;
        setNationalTrendData(nationalData);

        // Fetch state-specific trends
        const { data: stateData, error: stateError } = await supabase.functions.invoke('google-trends', {
          body: { 
            keyword: businessProfile.category,
            geo: `US-${businessProfile.state}`
          }
        });

        if (stateError) throw stateError;
        setStateTrendData(stateData);

        // Check if category has changed since last visit
        if (lastCategory && lastCategory !== businessProfile.category) {
          toast({
            title: "Category Change Detected",
            description: `Your business category has been updated from "${lastCategory}" to "${businessProfile.category}". The trends data has been refreshed accordingly.`,
          });
        }
        setLastCategory(businessProfile.category);

      } catch (error: any) {
        console.error('Error fetching trends:', error);
        toast({
          title: "Error fetching trend data",
          description: error.message,
          variant: "destructive",
        });
      }
    };

    fetchTrends();
  }, [businessProfile?.category, businessProfile?.state, toast, lastCategory]);

  const renderContent = () => {
    if (profileLoading) {
      return (
        <div className="space-y-4">
          <Skeleton className="h-8 w-1/3" />
          <Skeleton className="h-[400px] w-full" />
        </div>
      );
    }

    if (profileError) {
      return (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            Unable to load business profile. Please try again later.
          </AlertDescription>
        </Alert>
      );
    }

    if (!businessProfile?.category) {
      return (
        <Alert>
          <Info className="h-4 w-4" />
          <AlertTitle>Missing Information</AlertTitle>
          <AlertDescription>
            Please set up your business profile with a category to view trends.
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <>
        <div className="space-y-6">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">Google Trends Analysis</h1>
            <p className="text-sm text-gray-500 mt-1">
              Analyzing trends for "{businessProfile.category}" over the last 12 months
            </p>
          </div>

          <TrendsGuide />

          <Tabs defaultValue="national" className="space-y-4">
            <TabsList>
              <TabsTrigger value="national">National Trends</TabsTrigger>
              <TabsTrigger value="state">State Trends</TabsTrigger>
            </TabsList>
            
            <TabsContent value="national">
              <TrendChart 
                data={nationalTrendData}
                title="National Interest Trends"
                description={`Showing nationwide interest in "${businessProfile.category}"`}
              />
            </TabsContent>
            
            <TabsContent value="state">
              <TrendChart 
                data={stateTrendData}
                title={`${businessProfile.state} Interest Trends`}
                description={`Showing interest in "${businessProfile.category}" within ${businessProfile.state}`}
              />
            </TabsContent>
          </Tabs>
        </div>
      </>
    );
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 p-4 md:p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            {renderContent()}
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}