export const integrations = {
  title: "Integrations",
  subtitle: "Connect your business with popular platforms",
  
  google: {
    title: "Google Services",
    subtitle: "Connect with Google's business tools",
    allServices: "Connect your Google Business Profile, Analytics, and Search Console to optimize your online presence",
    connect: "Connect"
  },
  
  facebook: {
    title: "Facebook",
    subtitle: "Manage your Facebook Business presence",
    comingSoon: "Coming Soon"
  },
  
  tiktok: {
    title: "TikTok",
    subtitle: "Expand your reach on TikTok",
    comingSoon: "Coming Soon"
  }
};