import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { ArticleForm } from "./articles/ArticleForm";
import { ArticleList } from "./articles/ArticleList";

export const ResourceManagement = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [editingArticle, setEditingArticle] = useState<any>(null);

  const { data: articles, isLoading } = useQuery({
    queryKey: ['admin-articles'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const createArticle = useMutation({
    mutationFn: async (articleData: any) => {
      const { data, error } = await supabase
        .from('articles')
        .insert([{ ...articleData, status: 'draft' }])
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-articles'] });
      toast({
        title: "Success",
        description: "Article created successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to create article",
        variant: "destructive",
      });
    },
  });

  const updateArticle = useMutation({
    mutationFn: async (article: any) => {
      const { data, error } = await supabase
        .from('articles')
        .update(article)
        .eq('id', article.id)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-articles'] });
      setEditingArticle(null);
      toast({
        title: "Success",
        description: "Article updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update article",
        variant: "destructive",
      });
    },
  });

  const deleteArticle = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase
        .from('articles')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-articles'] });
      toast({
        title: "Success",
        description: "Article deleted successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete article",
        variant: "destructive",
      });
    },
  });

  const publishArticle = useMutation({
    mutationFn: async (id: string) => {
      const { data, error } = await supabase
        .from('articles')
        .update({ status: 'published', published_at: new Date().toISOString() })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin-articles'] });
      toast({
        title: "Success",
        description: "Article published successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to publish article",
        variant: "destructive",
      });
    },
  });

  const handleSubmit = (articleData: any) => {
    if (editingArticle) {
      updateArticle.mutate({ ...articleData, id: editingArticle.id });
    } else {
      createArticle.mutate(articleData);
    }
  };

  return (
    <div className="space-y-6">
      <ArticleForm
        article={editingArticle}
        onSubmit={handleSubmit}
        onCancel={editingArticle ? () => setEditingArticle(null) : undefined}
      />
      <ArticleList
        articles={articles || []}
        onEdit={setEditingArticle}
        onDelete={(id) => deleteArticle.mutate(id)}
        onPublish={(id) => publishArticle.mutate(id)}
      />
    </div>
  );
};