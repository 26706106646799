import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignupFlowSteps } from "@/components/onboarding/SignupFlowSteps";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

const CompleteProfile = () => {
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const hostedpage = searchParams.get('hostedpage');

  useEffect(() => {
    const fetchTempUserData = async () => {
      if (!hostedpage) {
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('temp_user_data')
          .select('*')
          .eq('hostedpage_id', hostedpage)
          .maybeSingle();

        if (error) {
          console.error('Error fetching temp user data:', error);
          toast({
            title: "Error",
            description: "Could not load your information. Please try again.",
            variant: "destructive",
          });
        } else if (data) {
          console.log('Found temp user data:', data);
          setUserData(data);
        } else {
          console.log('No temp user data found for hostedpage:', hostedpage);
          toast({
            title: "Error",
            description: "Could not find your information. Please try again or contact support.",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTempUserData();
  }, [hostedpage, toast]);

  if (isLoading) {
    return <div className="min-h-screen bg-background flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#e0933c]"></div>
    </div>;
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <SignupFlowSteps
          step={1}
          personalInfo={{
            email: userData?.email || '',
            firstName: userData?.first_name || '',
            lastName: userData?.last_name || '',
            phoneNumber: userData?.phone_number || '',
          }}
          businessInfo={{
            businessName: userData?.business_name || '',
            streetAddress: userData?.street_address || '',
            city: userData?.city || '',
            state: userData?.state || '',
            zipCode: userData?.zip_code || '',
          }}
          onCombinedInfoComplete={(data) => {
            console.log('Form completed:', data);
          }}
          onCheckoutComplete={() => {
            // Not needed for this flow
          }}
          onGoogleConnectComplete={() => {
            // Not needed for this flow
          }}
        />
      </div>
    </div>
  );
};

export default CompleteProfile;