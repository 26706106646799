import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SubscriptionRow } from "./SubscriptionRow";

interface SubscriptionListProps {
  subscriptions: any[];
  activeTab: string;
  isLoading: boolean;
}

export const SubscriptionList = ({ subscriptions, activeTab, isLoading }: SubscriptionListProps) => {
  const filteredSubscriptions = subscriptions?.filter((sub) => {
    if (activeTab === "active") {
      return sub.status?.toLowerCase() === "active" || 
             sub.authorize_status?.toLowerCase() === "active";
    }
    if (activeTab === "pending") {
      return sub.status?.toLowerCase() === "pending" || 
             sub.authorize_status?.toLowerCase() === "pending";
    }
    if (activeTab === "cancelled") {
      return ["cancelled", "canceled", "terminated"].includes(sub.status?.toLowerCase()) || 
             ["cancelled", "canceled", "terminated"].includes(sub.authorize_status?.toLowerCase());
    }
    if (activeTab === "expiring") return sub.has_expiring_card;
    if (activeTab === "declined") return sub.has_declined_transaction;
    return true; // "all" tab
  });

  if (isLoading) {
    return <div className="p-8 text-center">Loading subscriptions...</div>;
  }

  console.log('Filtered Subscriptions:', filteredSubscriptions);
  console.log('Active Tab:', activeTab);
  console.log('Original Subscriptions:', subscriptions);

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Client</TableHead>
            <TableHead>Package</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Next Billing</TableHead>
            <TableHead>Payment Status</TableHead>
            <TableHead>Alerts</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredSubscriptions && filteredSubscriptions.length > 0 ? (
            filteredSubscriptions.map((subscription) => (
              <SubscriptionRow 
                key={subscription.id} 
                subscription={subscription} 
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center py-4">
                No subscriptions found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};