import { BarChart2, Globe, MapPin, Phone, Search, TrendingUp } from "lucide-react";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { MetricCard } from "@/components/MetricCard";
import { Card } from "@/components/ui/card";
import { TodoList } from "@/components/dashboard/TodoList";
import { GettingStarted } from "@/components/dashboard/GettingStarted";
import { ProfileCompletionCard } from "@/components/business/ProfileCompletionCard";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

const Dashboard = () => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const { data: businessProfile, isLoading } = useQuery({
    queryKey: ['businessProfile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('business_profiles')
        .select('*')
        .eq('user_id', session.user.id)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching business profile:', error);
        toast({
          title: "Error",
          description: "There was an error loading your business profile",
          variant: "destructive",
        });
        return null;
      }

      return data;
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Simulated metrics data - In production, this would come from your API
  const metrics = {
    impressions: {
      maps: 1234,
      search: 5678,
      trend: 12.4,
    },
    clicks: {
      directions: 456,
      calls: 234,
      website: 789,
      trend: 18.2,
    },
    keywords: [
      { keyword: "Local Restaurant", position: 2, trend: 1 },
      { keyword: "Best Pizza", position: 5, trend: -2 },
      { keyword: "Italian Food", position: 1, trend: 0 },
    ],
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 p-4 md:p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="flex justify-between items-center mb-8">
              <div>
                <h1 className="text-3xl font-bold text-gray-900">{t('dashboard.title')}</h1>
                <p className="text-gray-500 mt-1">
                  Welcome back! Here's your business performance overview.
                </p>
              </div>
              <SidebarTrigger />
            </div>

            <GettingStarted />

            <TodoList />

            {businessProfile && (
              <ProfileCompletionCard profile={businessProfile} />
            )}

            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mt-8">
              <MetricCard
                title="Google Maps Views"
                value={metrics.impressions.maps.toLocaleString()}
                icon={<MapPin className="h-5 w-5 text-[#F97316]" />}
                trend="up"
                trendValue={`${metrics.impressions.trend}%`}
                className="bg-white shadow-sm hover:shadow-md transition-shadow"
              />
              <MetricCard
                title="Search Impressions"
                value={metrics.impressions.search.toLocaleString()}
                icon={<Search className="h-5 w-5 text-[#F97316]" />}
                trend="up"
                trendValue={`${metrics.clicks.trend}%`}
                className="bg-white shadow-sm hover:shadow-md transition-shadow"
              />
              <MetricCard
                title="Direction Requests"
                value={metrics.clicks.directions.toLocaleString()}
                icon={<Globe className="h-5 w-5 text-[#F97316]" />}
                trend="up"
                trendValue={`${metrics.clicks.trend}%`}
                className="bg-white shadow-sm hover:shadow-md transition-shadow"
              />
              <MetricCard
                title="Phone Calls"
                value={metrics.clicks.calls.toLocaleString()}
                icon={<Phone className="h-5 w-5 text-[#F97316]" />}
                trend="up"
                trendValue={`${metrics.clicks.trend}%`}
                className="bg-white shadow-sm hover:shadow-md transition-shadow"
              />
            </div>

            <div className="mt-8 grid gap-6 md:grid-cols-2">
              <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
                <h2 className="text-xl font-semibold mb-4 text-gray-900">Recent Business Updates</h2>
                <div className="space-y-3">
                  <div className="text-gray-500">No recent updates</div>
                </div>
              </Card>

              <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
                <h2 className="text-xl font-semibold mb-4 text-gray-900">Top Keywords</h2>
                <div className="space-y-3">
                  {metrics.keywords.map((item) => (
                    <div key={item.keyword} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-700">{item.keyword}</span>
                        <span className={`text-sm ${item.trend > 0 ? 'text-green-500' : item.trend < 0 ? 'text-red-500' : 'text-gray-500'}`}>
                          {item.trend > 0 ? '↑' : item.trend < 0 ? '↓' : '→'}
                        </span>
                      </div>
                      <span className="font-medium text-gray-900">Position: {item.position}</span>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Dashboard;
