import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Receipt } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";

export const PaymentHistory = () => {
  const { t } = useTranslation();
  
  const { data: subscription } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'active')
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    }
  });

  const generatePaymentHistory = (subscription: any) => {
    if (!subscription) return [];
    
    const history = [];
    const startDate = new Date(subscription.created_at);
    const amount = subscription.price;

    for (let i = 0; i < 6; i++) {
      const date = new Date(startDate);
      date.setMonth(date.getMonth() - i);
      
      history.push({
        id: i,
        date: date.toISOString(),
        amount: amount,
        status: t('billing.paymentHistory.paid'),
      });
    }

    return history;
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const paymentHistory = generatePaymentHistory(subscription);

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <Receipt className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{t('billing.paymentHistory.title')}</h2>
          <p className="text-sm text-gray-500">{t('billing.paymentHistory.subtitle')}</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-gray-100">
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.date')}</th>
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.amount')}</th>
              <th className="pb-3 text-sm font-medium text-gray-500">{t('billing.paymentHistory.status')}</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {paymentHistory.map((payment) => (
              <tr key={payment.id}>
                <td className="py-4 text-gray-900">{formatDate(payment.date)}</td>
                <td className="py-4 text-gray-900">${payment.amount}</td>
                <td className="py-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    {payment.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};