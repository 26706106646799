import { FormBusinessProfile } from "../types";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface SeoFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const SeoFields = ({ profile, setProfile }: SeoFieldsProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">SEO Information</h3>
      
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="seo_campaign_goal">SEO Campaign Goal</Label>
          <Input
            id="seo_campaign_goal"
            value={profile.seo_campaign_goal || ''}
            onChange={(e) => setProfile({ ...profile, seo_campaign_goal: e.target.value })}
            placeholder="Enter your SEO campaign goal"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="desired_keywords">Desired Keywords</Label>
          <Input
            id="desired_keywords"
            value={profile.desired_keywords?.join(', ') || ''}
            onChange={(e) => setProfile({ 
              ...profile, 
              desired_keywords: e.target.value.split(',').map(k => k.trim()).filter(k => k)
            })}
            placeholder="Enter keywords separated by commas"
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="short_description">Short Description (for search results)</Label>
        <Textarea
          id="short_description"
          value={profile.short_description || ''}
          onChange={(e) => setProfile({ ...profile, short_description: e.target.value })}
          placeholder="Brief description of your business"
          className="h-20"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="long_description">Long Description</Label>
        <Textarea
          id="long_description"
          value={profile.long_description || ''}
          onChange={(e) => setProfile({ ...profile, long_description: e.target.value })}
          placeholder="Detailed description of your business"
          className="h-32"
        />
      </div>
    </div>
  );
};