import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { BillingOverview } from "@/components/billing/BillingOverview";
import { PaymentHistory } from "@/components/billing/PaymentHistory";
import { BillingAddress } from "@/components/billing/BillingAddress";
import { CancelSubscription } from "@/components/billing/CancelSubscription";
import { UpdateCard } from "@/components/billing/UpdateCard";
import { useTranslation } from "react-i18next";

const Billing = () => {
  const { t } = useTranslation();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 p-4 md:p-8">
          <div className="max-w-6xl mx-auto space-y-8">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-900">{t('billing.title')}</h1>
              <p className="text-sm text-gray-500">{t('billing.subtitle')}</p>
            </div>
            <div className="grid gap-8 md:grid-cols-2">
              <BillingOverview />
              <BillingAddress />
            </div>
            <UpdateCard />
            <PaymentHistory />
            <CancelSubscription />
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Billing;