import { PaymentForm } from "@/components/billing/PaymentForm";
import { BillingForm } from "./checkout/BillingForm";
import { CombinedFormData } from "./types";

interface CheckoutStepProps {
  onComplete: () => void;
  formData: CombinedFormData;
}

export const CheckoutStep = ({ onComplete, formData }: CheckoutStepProps) => {
  return (
    <div className="space-y-6">
      <PaymentForm
        packageType="maps_seo"
        amount={598}
        onSuccess={onComplete}
        isSignupFlow={true}
        customerInfo={formData}
      />
    </div>
  );
};
