import { useState, useEffect } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export const useAuthState = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const fetchProfile = async (userId: string) => {
    try {
      console.log('[useAuthState] Starting profile fetch:', {
        userId,
        timestamp: new Date().toISOString()
      });

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('[useAuthState] Error fetching profile:', {
          error,
          userId,
          timestamp: new Date().toISOString()
        });
        throw error;
      }

      console.log('[useAuthState] Profile fetched successfully:', {
        hasData: !!data,
        userId,
        timestamp: new Date().toISOString()
      });
      setProfile(data);
    } catch (error: any) {
      console.error('[useAuthState] Error in fetchProfile:', {
        error,
        userId,
        timestamp: new Date().toISOString(),
        stack: error.stack
      });
      toast({
        title: "Error",
        description: "Failed to fetch user profile",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        console.log('[useAuthState] Starting auth initialization');
        const { data: { session: initialSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('[useAuthState] Error getting initial session:', {
            error: sessionError,
            timestamp: new Date().toISOString()
          });
          throw sessionError;
        }

        console.log('[useAuthState] Initial session:', {
          hasSession: !!initialSession,
          userId: initialSession?.user?.id,
          timestamp: new Date().toISOString()
        });

        if (initialSession?.user) {
          setSession(initialSession);
          await fetchProfile(initialSession.user.id);
        }
      } catch (error) {
        console.error('[useAuthState] Error in auth initialization:', {
          error,
          timestamp: new Date().toISOString()
        });
        toast({
          title: "Authentication Error",
          description: "There was a problem initializing authentication",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, currentSession) => {
      console.log('[useAuthState] Auth state change:', {
        event,
        hasSession: !!currentSession,
        userId: currentSession?.user?.id,
        timestamp: new Date().toISOString()
      });
      
      if (currentSession?.user) {
        setSession(currentSession);
        await fetchProfile(currentSession.user.id);
      } else {
        setSession(null);
        setProfile(null);
      }
    });

    return () => {
      console.log('[useAuthState] Cleaning up subscription');
      subscription.unsubscribe();
    };
  }, [toast]);

  return { session, profile, loading, setSession, setProfile, setLoading };
};