export const common = {
  dashboard: "Dashboard",
  resources: "Resources",
  videos: "Videos",
  academy: "Academy",
  googleTrends: "Google Trends",
  myAccount: "My Account",
  businessProfile: "Business Profile",
  integrations: "Integrations",
  billing: "Billing",
  settings: "Settings",
  support: "Support",
  logout: "Logout",
  logoutSuccess: "You have been logged out successfully.",
  adminPanel: "Admin Panel",
  partner: "Partner Portal",
  reports: "Reports"
};