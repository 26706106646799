import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SubscriptionList } from "./SubscriptionList";

interface BillingTabsProps {
  activeTab: string;
  onTabChange: (value: string) => void;
  subscriptionData: any[];
  isLoadingSubscriptions: boolean;
}

export const BillingTabs = ({ 
  activeTab, 
  onTabChange, 
  subscriptionData,
  isLoadingSubscriptions 
}: BillingTabsProps) => {
  return (
    <Tabs value={activeTab} onValueChange={onTabChange}>
      <TabsList>
        <TabsTrigger value="active">Active</TabsTrigger>
        <TabsTrigger value="pending">Pending</TabsTrigger>
        <TabsTrigger value="cancelled">Cancelled</TabsTrigger>
        <TabsTrigger value="expiring">Expiring Cards</TabsTrigger>
        <TabsTrigger value="declined">Declined Payments</TabsTrigger>
        <TabsTrigger value="all">All</TabsTrigger>
      </TabsList>

      {["active", "pending", "cancelled", "expiring", "declined", "all"].map((tab) => (
        <TabsContent key={tab} value={tab}>
          <SubscriptionList 
            subscriptions={subscriptionData} 
            activeTab={tab}
            isLoading={isLoadingSubscriptions}
          />
        </TabsContent>
      ))}
    </Tabs>
  );
};