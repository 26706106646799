import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const ArticleAnalytics = () => {
  const { data: analytics, isLoading } = useQuery({
    queryKey: ['article-analytics'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('articles')
        .select(`
          *,
          article_analytics (
            total_views,
            total_completions
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      return data.map((article: any) => ({
        ...article,
        total_views: article.article_analytics?.[0]?.total_views || 0,
        total_completions: article.article_analytics?.[0]?.total_completions || 0,
        completion_rate: article.article_analytics?.[0]?.total_views
          ? Math.round(
              (article.article_analytics[0].total_completions /
                article.article_analytics[0].total_views) *
                100
            )
          : 0,
      }));
    },
  });

  const chartData = analytics?.slice(0, 10).map((article) => ({
    name: article.title.substring(0, 20) + "...",
    views: article.total_views,
    completions: article.total_completions,
  }));

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h2 className="text-lg font-semibold mb-4">Article Performance</h2>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
              <YAxis />
              <Tooltip />
              <Bar dataKey="views" fill="#93c5fd" name="Views" />
              <Bar dataKey="completions" fill="#22c55e" name="Completions" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Card className="p-6">
        <h2 className="text-lg font-semibold mb-4">Detailed Analytics</h2>
        <div className="relative overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Title</TableHead>
                <TableHead>Views</TableHead>
                <TableHead>Completions</TableHead>
                <TableHead>Completion Rate</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {analytics?.map((article) => (
                <TableRow key={article.id}>
                  <TableCell className="font-medium">{article.title}</TableCell>
                  <TableCell>{article.total_views}</TableCell>
                  <TableCell>{article.total_completions}</TableCell>
                  <TableCell>{article.completion_rate}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
};