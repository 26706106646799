import { ReactNode, useEffect } from "react";
import { useSessionInit } from "./useSessionInit";
import { useProfile } from "./useProfile";
import { LoadingSpinner } from "./LoadingSpinner";
import { useToast } from "@/hooks/use-toast";

interface SessionProviderProps {
  children: (props: {
    session: any;
    profile: any;
    loading: boolean;
  }) => ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const { session, loading: sessionLoading, setLoading } = useSessionInit();
  const { profile, loading: profileLoading, profileError } = useProfile(session);
  const { toast } = useToast();

  useEffect(() => {
    if (sessionLoading) {
      console.log("[SessionProvider] Session still loading...", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });
      return;
    }

    if (!session && !sessionLoading) {
      console.log("[SessionProvider] No session and not loading, setting loading to false", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });
      setLoading(false);
      return;
    }

    // If we have a session but profile loading failed, show error and continue
    if (session && profileError) {
      console.error("[SessionProvider] Profile loading failed, continuing with session", {
        error: profileError,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });
      toast({
        title: "Warning",
        description: "Some user data couldn't be loaded. You may experience limited functionality.",
        variant: "destructive",
      });
      setLoading(false);
      return;
    }

    if ((profile || profileError) && !profileLoading) {
      console.log("[SessionProvider] Profile loaded or error, setting loading to false", {
        hasProfile: !!profile,
        hasError: !!profileError,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });
      setLoading(false);
    }
  }, [session, profile, profileError, profileLoading, setLoading, sessionLoading, toast]);

  const isLoading = (sessionLoading || (profileLoading && session)) && !profileError;
  
  console.log("[SessionProvider] State update", {
    sessionLoading,
    profileLoading,
    hasSession: !!session,
    hasProfile: !!profile,
    isLoading,
    timestamp: new Date().toISOString(),
    currentPath: window.location.pathname
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <>{children({ session, profile, loading: isLoading })}</>;
};