import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { RichTextEditor } from "./RichTextEditor";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const CATEGORIES = [
  "Getting Started",
  "Business Growth",
  "Marketing",
  "SEO",
  "Social Media",
  "Customer Service",
  "Finance",
  "Operations",
];

interface ArticleFormProps {
  article?: any;
  onSubmit: (articleData: any) => void;
  onCancel?: () => void;
}

export const ArticleForm = ({ article, onSubmit, onCancel }: ArticleFormProps) => {
  const [formData, setFormData] = useState({
    title: article?.title || "",
    description: article?.description || "",
    link: article?.link || "",
    category: article?.category || "",
    min_account_age_months: article?.min_account_age_months || 0,
    premium: article?.premium || false,
    read_time_minutes: article?.read_time_minutes || 5,
    tags: article?.tags || [],
    status: article?.status || "draft"
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Card className="p-6">
      <h2 className="text-lg font-semibold mb-4">
        {article ? "Edit Article" : "Create New Article"}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Title</label>
            <Input
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Category</label>
            <Select
              value={formData.category}
              onValueChange={(value) => setFormData({ ...formData, category: value })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                {CATEGORIES.map((category) => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Description</label>
          <RichTextEditor
            content={formData.description}
            onChange={(content) => setFormData({ ...formData, description: content })}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Link</label>
            <Input
              value={formData.link}
              onChange={(e) => setFormData({ ...formData, link: e.target.value })}
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Min Account Age (months)</label>
            <Input
              type="number"
              value={formData.min_account_age_months}
              onChange={(e) => setFormData({ ...formData, min_account_age_months: parseInt(e.target.value) })}
              required
            />
          </div>
        </div>

        <div className="flex justify-end gap-2">
          {onCancel && (
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button type="submit">
            {article ? "Update Article" : "Create Article"}
          </Button>
        </div>
      </form>
    </Card>
  );
};