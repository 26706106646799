import { FormBusinessProfile } from "./types";
import { BasicInfoFields } from "./form-sections/BasicInfoFields";
import { AddressFields } from "./form-sections/AddressFields";
import { OpeningHoursFields } from "./form-sections/OpeningHoursFields";
import { SeoFields } from "./form-sections/SeoFields";
import { AdditionalInfoFields } from "./form-sections/AdditionalInfoFields";
import { DEFAULT_BUSINESS_HOURS } from "./types/businessHours";

interface BusinessFormFieldsProps {
  profile: FormBusinessProfile;
  setProfile: (profile: FormBusinessProfile) => void;
}

export const BusinessFormFields = ({ profile, setProfile }: BusinessFormFieldsProps) => {
  // Ensure opening_hours_json has a valid structure
  const updatedProfile = {
    ...profile,
    opening_hours_json: Object.keys(profile.opening_hours_json || {}).length === 0 
      ? DEFAULT_BUSINESS_HOURS 
      : profile.opening_hours_json
  };

  return (
    <div className="space-y-8">
      <BasicInfoFields profile={updatedProfile} setProfile={setProfile} />
      <AddressFields profile={updatedProfile} setProfile={setProfile} />
      <OpeningHoursFields profile={updatedProfile} setProfile={setProfile} />
      <SeoFields profile={updatedProfile} setProfile={setProfile} />
      <AdditionalInfoFields profile={updatedProfile} setProfile={setProfile} />
    </div>
  );
};