export const dashboard = {
  title: "Panel de Control",
  subtitle: "¡Bienvenido de nuevo, Chris! Aquí tienes un resumen del rendimiento de tu negocio.",
  toggleSidebar: "Alternar Barra Lateral",
  tasks: {
    title: "En qué estamos trabajando:",
    columns: {
      task: "Tarea",
      status: "Estado"
    },
    list: {
      signupMembership: "Regístrate para una membresía",
      signupMembershipDescription: "Elige y activa tu plan de membresía SEO para Mapas.",
      signupMembershipStatus: "Completado",

      connectGoogleProfile: "Conecta el Perfil de Empresa de Google",
      connectGoogleProfileDescription: "Conecta tu Perfil de Empresa de Google para mejorar tu presencia en SEO de Mapas.",
      connectGoogleProfileStatus: "Completado",

      reviewGoogleListing: "Revisa el listado de Google Maps",
      reviewGoogleListingDescription: "Revisa y audita el listado actual de Google Maps.",
      reviewGoogleListingStatus: "Completado",

      optimizeMapsInfo: "Optimiza la información en Mapas",
      optimizeMapsInfoDescription: "Optimiza la información del negocio e imágenes en Google Maps.",
      optimizeMapsInfoStatus: "Completado",

      localCitationsAudit: "Auditoría de citas locales",
      localCitationsAuditDescription: "Inicia la auditoría de citas locales y la gestión inicial.",
      localCitationsAuditStatus: "Completado",

      localKeywordStrategy: "Estrategia de palabras clave locales",
      localKeywordStrategyDescription: "Establece un enfoque de radio de 3 millas y comienza con palabras clave locales.",
      localKeywordStrategyStatus: "Completado",

      createAccount: "Crear una cuenta",
      createAccountDescription: "Regístrate para comenzar tu recorrido de SEO para Mapas.",
      createAccountStatus: "Completado"
    }
  },
  profileCompletion: {
    title: "Completitud del Perfil",
    percentage: "0%",
    description: "¡Completa tu perfil para aumentar tus posibilidades de ser encontrado por clientes!",
    missingFields: {
      businessName: "Nombre del Negocio faltante",
      website: "Sitio Web faltante",
      streetAddress: "Dirección faltante",
      city: "Ciudad faltante",
      state: "Estado faltante",
      zipCode: "Código Postal faltante",
      category: "Categoría faltante",
      phoneNumber: "Número de Teléfono faltante",
      openingHours: "Horario de Apertura faltante",
      businessEmail: "Correo Electrónico faltante",
      shortDescription: "Descripción Breve faltante",
      longDescription: "Descripción Detallada faltante",
      facebookUrl: "URL de Facebook faltante",
      instagramUrl: "URL de Instagram faltante"
    },
    completeProfile: "Completar Perfil"
  },
  performanceMetrics: {
    mapsViews: "Vistas en Google Maps",
    searchImpressions: "Impresiones de Búsqueda",
    directionRequests: "Solicitudes de Dirección",
    phoneCalls: "Llamadas Telefónicas"
  },
  recentUpdates: {
    title: "Actualizaciones Recientes del Negocio",
    noUpdates: "Sin actualizaciones recientes"
  },
  topKeywords: {
    title: "Principales Palabras Clave",
    keywords: {
      localRestaurant: {
        name: "Restaurante Local",
        position: "Posición:"
      },
      bestPizza: {
        name: "Mejor Pizza",
        position: "Posición:"
      },
      italianFood: {
        name: "Comida Italiana",
        position: "Posición:"
      }
    }
  },
  welcome: "¡Bienvenido de nuevo! Aquí está tu resumen de SEO.",
  noTasks: "No hay tareas disponibles en este momento. ¡Vuelve más tarde!",
  onboardingProgress: {
    title: "Progreso de Tareas",
    subtitle: "Sigue tu progreso",
    tasks: {
      selectPackage: "Seleccionar paquete",
      setupAccount: "Configurar tu cuenta",
      grantAccess: "Conceder acceso a Google",
      connectNow: "Conectar Ahora"
    },
    status: {
      completed: "Completado",
      in_progress: "En Progreso",
      not_started: "No Iniciado",
      pending: "Pendiente"
    }
  },
  nextSteps: {
    title: "Próximos Pasos",
    subtitle: "Recursos para ayudarte a comenzar",
    articles: {
      readMore: "Leer Más"
    }
  },
  metrics: {
    averagePosition: {
      title: "Posición Promedio",
      trend: "2.1%"
    },
    keywordsTracked: {
      title: "Palabras Clave Rastreadas",
      trend: "12 nuevas"
    },
    organicTraffic: {
      title: "Tráfico Orgánico",
      trend: "18.2%"
    },
    backlinks: {
      title: "Backlinks",
      trend: "4 nuevos"
    }
  }
};
