import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

export const useSessionInit = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const initialized = useRef(false);
  const initializationTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const initializeSession = async () => {
      if (initialized.current) {
        console.log("[useSessionInit] Session already initialized, skipping");
        return;
      }
      initialized.current = true;

      console.log("[useSessionInit] Starting session initialization", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });

      try {
        const { data: { session: initialSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;

        console.log("[useSessionInit] Initial session fetch:", { 
          hasSession: !!initialSession,
          userId: initialSession?.user?.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        
        setSession(initialSession);
        
        if (initialSession?.user) {
          const currentPath = window.location.pathname;
          if (['/login', '/', '/signup', '/resources'].includes(currentPath) || currentPath.startsWith('/signup/')) {
            console.log("[useSessionInit] Redirecting to dashboard", {
              from: currentPath,
              timestamp: new Date().toISOString()
            });
            navigate('/dashboard', { replace: true });
          }
        }
      } catch (error: any) {
        console.error('[useSessionInit] Session initialization error:', {
          error,
          timestamp: new Date().toISOString(),
          stack: error.stack,
          currentPath: window.location.pathname
        });
        setSession(null);
        queryClient.clear();
        toast({
          title: "Session Error",
          description: "Please sign in again",
          variant: "destructive",
        });
        navigate('/login', { replace: true });
      }
    };

    // Set a timeout to prevent infinite loading
    initializationTimeout.current = setTimeout(() => {
      if (loading) {
        console.error("[useSessionInit] Session initialization timeout", {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        setLoading(false);
        setSession(null);
        navigate('/login', { replace: true });
      }
    }, 10000);

    initializeSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, newSession) => {
      console.log("[useSessionInit] Auth state change:", {
        event,
        hasSession: !!newSession,
        userId: newSession?.user?.id,
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname
      });

      if (event === 'SIGNED_OUT') {
        console.log("[useSessionInit] User signed out", {
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        setSession(null);
        queryClient.clear();
        navigate('/login', { replace: true });
      } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        console.log("[useSessionInit] User signed in or token refreshed", {
          userId: newSession?.user?.id,
          timestamp: new Date().toISOString(),
          currentPath: window.location.pathname
        });
        setSession(newSession);
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        
        const currentPath = window.location.pathname;
        if (['/login', '/', '/signup', '/resources'].includes(currentPath) || currentPath.startsWith('/signup/')) {
          console.log("[useSessionInit] Redirecting to dashboard", {
            from: currentPath,
            timestamp: new Date().toISOString()
          });
          navigate('/dashboard', { replace: true });
        }
      }
      setLoading(false);
    });

    return () => {
      console.log("[useSessionInit] Cleaning up subscription", {
        timestamp: new Date().toISOString(),
        currentPath: window.location.pathname,
        isInitialized: initialized.current
      });
      if (initializationTimeout.current) {
        clearTimeout(initializationTimeout.current);
      }
      if (initialized.current) {
        subscription.unsubscribe();
      }
    };
  }, [navigate, queryClient, toast, loading]);

  return { session, loading, setLoading };
};