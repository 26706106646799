import { Home, User, Settings, Building2, CreditCard, Share2, BookOpen, ShieldCheck, LifeBuoy, Users, LayoutDashboard, TrendingUp, PlaySquare } from "lucide-react";

export type MenuItem = {
  titleKey?: string;
  icon?: React.ComponentType<{ className?: string }>;
  url?: string;
  divider?: boolean;
  condition?: boolean;
};

export const baseMenuItems: MenuItem[] = [
  { titleKey: "dashboard", icon: Home, url: "/dashboard" },
  { titleKey: "resources", icon: BookOpen, url: "/resources" },
  { titleKey: "videos", icon: PlaySquare, url: "/videos" },
  { titleKey: "googleTrends", icon: TrendingUp, url: "/google-trends" },
  { titleKey: "myAccount", icon: User, url: "/my-account" },
  { titleKey: "businessProfile", icon: Building2, url: "/business-profile" },
  { titleKey: "integrations", icon: Share2, url: "/integrations" },
  { titleKey: "billing", icon: CreditCard, url: "/billing" },
  { titleKey: "settings", icon: Settings, url: "/settings" },
  { titleKey: "support", icon: LifeBuoy, url: "/support" },
];

export const adminMenuItem: MenuItem = { 
  titleKey: "adminPanel", 
  icon: ShieldCheck, 
  url: "/admin" 
};

export const partnerMenuItem: MenuItem = { 
  titleKey: "partner", 
  icon: Users, 
  url: "/partner" 
};

export const reportsMenuItem: MenuItem = {
  titleKey: "reports",
  icon: LayoutDashboard,
  url: "/marketing-dashboard"
};