import { Button } from "@/components/ui/button";
import { Download, Image } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useToast } from "@/hooks/use-toast";

interface ImageDownloaderProps {
  imageUrl: string | null;
  fileName: string;
  label: string;
}

export const ImageDownloader = ({ imageUrl, fileName, label }: ImageDownloaderProps) => {
  const { toast } = useToast();

  const handleDownload = async () => {
    if (!imageUrl) {
      toast({
        title: "No file available",
        description: "This image hasn't been uploaded yet",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast({
        title: "Error downloading file",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Button
          variant={imageUrl ? "outline" : "ghost"}
          size="sm"
          onClick={handleDownload}
          className={!imageUrl ? "opacity-50 cursor-not-allowed" : ""}
        >
          {imageUrl ? (
            <>
              <Download className="h-4 w-4 mr-2" />
              {label}
            </>
          ) : (
            <>
              <Image className="h-4 w-4 mr-2" />
              No {label}
            </>
          )}
        </Button>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        {imageUrl ? (
          <>
            <div className="aspect-video w-full overflow-hidden rounded-lg">
              <img
                src={imageUrl}
                alt={label}
                className="object-cover w-full h-full"
              />
            </div>
            <p className="text-sm text-muted-foreground mt-2">
              Click to download {label.toLowerCase()}
            </p>
          </>
        ) : (
          <p className="text-sm text-muted-foreground">
            No {label.toLowerCase()} has been uploaded yet
          </p>
        )}
      </HoverCardContent>
    </HoverCard>
  );
};