import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { createSubscription } from "@/utils/payment/authorizeNetApi";

interface PaymentData {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  billingInfo: {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export const usePaymentSubmission = (
  packageType: string,
  amount: number,
  onSuccess: () => void,
  isSignupFlow: boolean = false
) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { toast } = useToast();

  const handlePayment = async (paymentData: PaymentData) => {
    setIsProcessing(true);
    console.log('Starting payment submission process...');
    console.log('Is signup flow:', isSignupFlow);

    try {
      let userId;

      if (!isSignupFlow) {
        // For regular authenticated flows, check session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw new Error('Authentication error: ' + sessionError.message);
        }

        if (!session) {
          console.error('No active session found');
          throw new Error('Not authenticated');
        }

        userId = session.user.id;
      } else {
        // For signup flow, we'll create the subscription first and link it to the user later
        console.log('Processing payment for signup flow');
      }

      console.log('Proceeding with payment...');
      
      // Create subscription with Authorize.net
      const authNetResponse = await createSubscription(
        {
          cardNumber: paymentData.cardNumber,
          expirationDate: paymentData.expiryDate,
          cardCode: paymentData.cvv
        },
        paymentData.billingInfo,
        amount,
        packageType
      );

      console.log('Authorize.net response received:', {
        success: authNetResponse.success,
        subscriptionId: authNetResponse.subscriptionId
      });

      if (!isSignupFlow) {
        // Only create database records for authenticated users
        await createDatabaseRecords(userId, authNetResponse.subscriptionId, paymentData, packageType, amount);
      }

      toast({
        title: "Payment Successful",
        description: "Your subscription has been activated.",
      });

      onSuccess();
    } catch (error: any) {
      console.error('Payment error:', error);
      console.error('Full error details:', {
        message: error.message,
        code: error.code,
        details: error.details
      });
      
      toast({
        title: "Payment Failed",
        description: error.message || "There was an error processing your payment",
        variant: "destructive",
      });
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const createDatabaseRecords = async (
    userId: string,
    subscriptionId: string,
    paymentData: PaymentData,
    packageType: string,
    amount: number
  ) => {
    // Create subscription record in database
    const { data: subscription, error: subError } = await supabase
      .from('subscriptions')
      .insert([{
        user_id: userId,
        package_type: packageType,
        price: amount,
        status: 'active',
        external_subscription_id: subscriptionId,
        billing_street_address: paymentData.billingInfo.street,
        billing_city: paymentData.billingInfo.city,
        billing_state: paymentData.billingInfo.state,
        billing_zip_code: paymentData.billingInfo.zipCode
      }])
      .select()
      .single();

    if (subError) {
      console.error('Subscription record creation error:', subError);
      throw subError;
    }

    console.log('Subscription record created:', subscription.id);

    // Create payment method record
    const [month, year] = paymentData.expiryDate.split('/');
    const { error: pmError } = await supabase
      .from('payment_methods')
      .insert([{
        user_id: userId,
        payment_profile_id: subscriptionId,
        last_four: paymentData.cardNumber.slice(-4),
        card_type: 'credit',
        expiry_month: month,
        expiry_year: year,
        is_default: true
      }]);

    if (pmError) {
      console.error('Payment method creation error:', pmError);
      throw pmError;
    }

    console.log('Payment method record created successfully');

    // Create payment transaction record
    const { error: txError } = await supabase
      .from('payment_transactions')
      .insert([{
        user_id: userId,
        subscription_id: subscription.id,
        amount: amount,
        status: 'completed',
        transaction_id: subscriptionId
      }]);

    if (txError) {
      console.error('Transaction record creation error:', txError);
      throw txError;
    }

    console.log('Payment transaction record created successfully');
  };

  return {
    handlePayment,
    isProcessing
  };
};