import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface BillingInfoFieldsProps {
  billingInfo: {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  onChange: (field: string, value: string) => void;
  isSubmitting: boolean;
}

export const BillingInfoFields = ({ billingInfo, onChange, isSubmitting }: BillingInfoFieldsProps) => {
  return (
    <div className="space-y-4">
      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="firstName">First Name</Label>
          <Input
            id="firstName"
            value={billingInfo.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            id="lastName"
            value={billingInfo.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            required
            disabled={isSubmitting}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={billingInfo.email}
          onChange={(e) => onChange('email', e.target.value)}
          required
          disabled={isSubmitting}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="street">Street Address</Label>
        <Input
          id="street"
          value={billingInfo.street}
          onChange={(e) => onChange('street', e.target.value)}
          required
          disabled={isSubmitting}
        />
      </div>

      <div className="grid md:grid-cols-3 gap-4">
        <div className="space-y-2">
          <Label htmlFor="city">City</Label>
          <Input
            id="city"
            value={billingInfo.city}
            onChange={(e) => onChange('city', e.target.value)}
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="state">State</Label>
          <Input
            id="state"
            value={billingInfo.state}
            onChange={(e) => onChange('state', e.target.value)}
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="zipCode">ZIP Code</Label>
          <Input
            id="zipCode"
            value={billingInfo.zipCode}
            onChange={(e) => onChange('zipCode', e.target.value)}
            required
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};