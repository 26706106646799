export interface BusinessHours {
  open: string;
  close: string;
}

export interface BusinessHoursSchedule {
  [key: string]: BusinessHours;
  monday: BusinessHours;
  tuesday: BusinessHours;
  wednesday: BusinessHours;
  thursday: BusinessHours;
  friday: BusinessHours;
  saturday: BusinessHours;
  sunday: BusinessHours;
}

export const DEFAULT_BUSINESS_HOURS: BusinessHoursSchedule = {
  monday: { open: "--:--", close: "--:--" },
  tuesday: { open: "--:--", close: "--:--" },
  wednesday: { open: "--:--", close: "--:--" },
  thursday: { open: "--:--", close: "--:--" },
  friday: { open: "--:--", close: "--:--" },
  saturday: { open: "--:--", close: "--:--" },
  sunday: { open: "--:--", close: "--:--" }
};