export const businessprofile = {
  title: "Perfil del Negocio",
  subtitle: "Administra los detalles y la presencia de tu negocio",
  form: {
    businessName: "Nombre del Negocio",
    businessEmail: "Correo Electrónico del Negocio",
    websiteUrl: "URL del Sitio Web",
    phoneNumber: "Número de Teléfono",
    category: "Categoría",
    openingDate: "Fecha de Apertura",
    openingHours: "Horario de Apertura",
    streetAddress: "Dirección",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    shortDescription: "Breve descripción de tu negocio",
    longDescription: "Descripción detallada de tu negocio",
    facebookUrl: "URL de Facebook",
    instagramUrl: "URL de Instagram",
    businessServices: "Ingresa los servicios separados por comas",
    seoCampaignGoal: "Meta de la Campaña SEO",
    desiredKeywords: "Palabras Clave Deseadas",
    paymentMethods: "Métodos de Pago",
    promotions: "Promociones",
    businessImages: "Imágenes del Negocio",
    uploadInstructions: "Sube el logotipo e imágenes de tu negocio. Formatos soportados: JPG, JPEG, PNG",
    businessLogo: "Logotipo del Negocio",
    uploadNewImage: "Subir Nueva Imagen",
    squareLogo: "Logotipo Cuadrado",
    primaryHeaderImage: "Imagen Principal de Encabezado",
    saveChanges: "Guardar Cambios"
  }
};
