import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Task, UserTask } from "./todo/types";
import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { format, addMonths, isSameMonth, isPast } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export const TodoList = () => {
  const { t } = useTranslation();

  const { data: session } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      return session;
    },
  });

  const { data: profile } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .maybeSingle();
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });

  // Query to check if user has an active subscription
  const { data: subscription } = useQuery({
    queryKey: ['subscription', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('status', 'active')
        .maybeSingle();
      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });

  const { data: userTasks, isLoading, error } = useQuery({
    queryKey: ['userTasks', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return [];
      
      // First, update tasks that are past their due date to completed
      const { error: updateError } = await supabase
        .from('user_tasks')
        .update({ 
          status: 'completed',
          completed_at: new Date().toISOString()
        })
        .eq('user_id', session.user.id)
        .eq('status', 'in_progress')
        .lt('due_date', new Date().toISOString());

      if (updateError) {
        console.error('Error updating overdue tasks:', updateError);
      }
      
      const { data: tasks, error } = await supabase
        .from('user_tasks')
        .select(`
          *,
          task:tasks (*)
        `)
        .eq('user_id', session.user.id)
        .order('due_date', { ascending: true });

      if (error) {
        console.error('Error fetching tasks:', error);
        throw error;
      }

      // Create a Map to store unique tasks, using task title as the key
      const uniqueTasksMap = new Map();
      tasks.forEach((task: UserTask) => {
        const taskTitle = task.task?.title;
        
        // Special handling for "Create an account" task
        if (taskTitle === 'Create an account') {
          task.status = 'completed';
        }
        
        // Special handling for "Signup for a membership" task
        if (taskTitle === 'Signup for a membership') {
          task.status = subscription ? 'completed' : 'in_progress';
        }
        
        // Mark task as completed if due date has passed
        if (task.due_date && isPast(new Date(task.due_date)) && task.status === 'in_progress') {
          task.status = 'completed';
        }
        
        // Only store the most recent task for each unique title
        if (!uniqueTasksMap.has(taskTitle) || 
            new Date(task.created_at) > new Date(uniqueTasksMap.get(taskTitle).created_at)) {
          uniqueTasksMap.set(taskTitle, task);
        }
      });

      // Convert the Map back to an array
      return Array.from(uniqueTasksMap.values()) as UserTask[];
    },
    enabled: !!session?.user?.id,
    refetchInterval: 60000, // Refetch every minute to check for status updates
  });

  if (isLoading) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">{t('common.loading')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <p>{t('common.error')}</p>
      </div>
    );
  }

  if (!userTasks?.length) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">{t('dashboard.noTasks')}</p>
      </div>
    );
  }

  // Calculate which month's tasks to show based on profile creation date
  const userCreationDate = new Date(profile?.created_at || new Date());
  const currentDate = new Date();
  const monthsSinceCreation = Math.floor(
    (currentDate.getTime() - userCreationDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  );
  const targetMonth = addMonths(userCreationDate, monthsSinceCreation);

  // Filter tasks for the current month based on due_date
  const currentMonthTasks = userTasks?.filter(task => 
    task.due_date && isSameMonth(new Date(task.due_date), targetMonth)
  );

  const renderTaskTitle = (userTask: UserTask) => {
    if (userTask.task?.title === 'Connect Google Business Profile') {
      return (
        <Link to="/integrations" className="text-[#F97316] hover:underline">
          {userTask.task.title}
        </Link>
      );
    }
    return userTask.task?.title;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-blue-100 text-blue-800'; // Default to in_progress styling
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'completed':
        return 'Completed';
      default:
        return 'In Progress'; // Default to "In Progress" for all non-completed tasks
    }
  };

  return (
    <Card className="p-6">
      <h2 className="text-xl font-semibold mb-4">
        What we're working on:
      </h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12"></TableHead>
            <TableHead>Task</TableHead>
            <TableHead className="w-32">Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentMonthTasks?.map((userTask) => (
            <TableRow key={userTask.id}>
              <TableCell>
                <Checkbox
                  checked={userTask.status === 'completed'}
                  disabled
                />
              </TableCell>
              <TableCell>
                <div>
                  <h3 className={`font-medium ${userTask.status === 'completed' ? 'line-through text-gray-500' : ''}`}>
                    {renderTaskTitle(userTask)}
                  </h3>
                  <p className={`text-sm ${userTask.status === 'completed' ? 'text-gray-400' : 'text-gray-500'}`}>
                    {userTask.task?.description}
                  </p>
                </div>
              </TableCell>
              <TableCell>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(userTask.status)}`}>
                  {getStatusText(userTask.status)}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};