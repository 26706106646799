import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { BusinessWithProfile } from "./types";
import { BusinessListItem } from "./BusinessListItem";

interface BusinessListProps {
  businesses: BusinessWithProfile[] | undefined;
  isLoading: boolean;
  onUpdate: () => void;
}

export const BusinessList = ({ businesses, isLoading, onUpdate }: BusinessListProps) => {
  if (isLoading) {
    return (
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, i) => (
          <Skeleton key={i} className="h-16 w-full" />
        ))}
      </div>
    );
  }

  if (!businesses?.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        No businesses found
      </div>
    );
  }

  return (
    <div className="border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Business Name</TableHead>
            <TableHead>Owner</TableHead>
            <TableHead>Contact</TableHead>
            <TableHead>Location</TableHead>
            <TableHead>Category</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {businesses.map((business) => (
            <BusinessListItem 
              key={business.id} 
              business={business}
              onUpdate={onUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};