import { Card } from "@/components/ui/card";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface TrendData {
  date: string;
  value: number;
}

interface TrendChartProps {
  data: TrendData[];
  title: string;
  description: string;
}

export function TrendChart({ data, title, description }: TrendChartProps) {
  return (
    <Card className="p-6">
      <div className="flex items-start justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
          <p className="text-sm text-gray-500 mt-1">{description}</p>
        </div>
      </div>
      <div className="h-[400px]">
        {data.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis 
                dataKey="date" 
                tick={{ fontSize: 12, fill: '#6B7280' }}
                angle={-45}
                textAnchor="end"
                tickMargin={10}
              />
              <YAxis 
                tick={{ fontSize: 12, fill: '#6B7280' }}
                domain={[0, 100]}
                label={{ value: 'Relative Interest', angle: -90, position: 'insideLeft' }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'white',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  padding: '8px'
                }}
              />
              <Legend />
              <Line 
                name="Interest Over Time"
                type="monotone" 
                dataKey="value" 
                stroke="#F97316" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, fill: '#F97316' }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Loading trend data...
          </div>
        )}
      </div>
    </Card>
  );
}