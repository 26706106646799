import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { SearchField } from "./types";

interface BusinessSearchBarProps {
  searchQuery: string;
  searchField: SearchField;
  onSearchQueryChange: (value: string) => void;
  onSearchFieldChange: (value: SearchField) => void;
  onSearch: () => void;
  isLoading: boolean;
}

export const BusinessSearchBar = ({
  searchQuery,
  searchField,
  onSearchQueryChange,
  onSearchFieldChange,
  onSearch,
  isLoading,
}: BusinessSearchBarProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="relative flex-1">
        <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
        <Input
          placeholder="Search businesses..."
          value={searchQuery}
          onChange={(e) => onSearchQueryChange(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && onSearch()}
          className="pl-10"
        />
      </div>
      <Select
        value={searchField}
        onValueChange={(value) => onSearchFieldChange(value as SearchField)}
      >
        <SelectTrigger className="w-full md:w-[200px]">
          <SelectValue placeholder="Search by..." />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="business_name">Business Name</SelectItem>
          <SelectItem value="email">Email</SelectItem>
          <SelectItem value="phone_number">Phone Number</SelectItem>
          <SelectItem value="zip_code">ZIP Code</SelectItem>
          <SelectItem value="category">Category</SelectItem>
        </SelectContent>
      </Select>
      <Button 
        onClick={onSearch} 
        disabled={isLoading}
        className="w-full md:w-auto"
      >
        {isLoading ? "Searching..." : "Search"}
      </Button>
    </div>
  );
};