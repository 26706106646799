import { format } from "date-fns";
import { Badge } from "@/components/ui/badge";
import { TableCell, TableRow } from "@/components/ui/table";

interface SubscriptionRowProps {
  subscription: any;
}

export const SubscriptionRow = ({ subscription }: SubscriptionRowProps) => {
  const getStatusBadgeColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case "active":
        return "bg-green-500";
      case "pending":
        return "bg-yellow-500";
      case "cancelled":
      case "canceled":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const lastPayment = subscription.payment_transactions?.[0];

  return (
    <TableRow>
      <TableCell>
        <div>
          <div className="font-medium">
            {subscription.profiles?.first_name} {subscription.profiles?.last_name}
          </div>
          <div className="text-sm text-gray-500">
            {subscription.profiles?.email}
          </div>
        </div>
      </TableCell>
      <TableCell>{subscription.package_type}</TableCell>
      <TableCell>
        <Badge className={getStatusBadgeColor(subscription.status || "")}>
          {subscription.status}
        </Badge>
      </TableCell>
      <TableCell>{formatCurrency(subscription.price)}</TableCell>
      <TableCell>
        {subscription.next_billing_date
          ? format(new Date(subscription.next_billing_date), "MMM d, yyyy")
          : "-"}
      </TableCell>
      <TableCell>
        <Badge
          className={getStatusBadgeColor(lastPayment?.status || "unknown")}
        >
          {lastPayment?.status || "No payments"}
        </Badge>
      </TableCell>
      <TableCell>
        <div className="space-y-1">
          {subscription.has_expiring_card && (
            <Badge className="bg-yellow-500">
              Card Expiring Soon
            </Badge>
          )}
          {subscription.has_declined_transaction && (
            <Badge className="bg-red-500">
              Payment Declined
            </Badge>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};