import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BusinessTable } from "@/components/admin/BusinessTable";
import { ArticleAnalytics } from "@/components/admin/ArticleAnalytics";
import { ResourceManagement } from "@/components/admin/ResourceManagement";
import { ClientBillingManager } from "@/components/admin/ClientBillingManager";
import { AdminHeader } from "@/components/admin/AdminHeader";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const AdminPanel = () => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <main className="flex-1 overflow-auto">
          <AdminHeader />
          <div className="container mx-auto p-4 md:p-8">
            <Tabs defaultValue="businesses" className="space-y-6">
              <TabsList className="w-full justify-start">
                <TabsTrigger value="businesses">Businesses</TabsTrigger>
                <TabsTrigger value="articles">Articles</TabsTrigger>
                <TabsTrigger value="resources">Resources</TabsTrigger>
                <TabsTrigger value="billing">Client Billing</TabsTrigger>
              </TabsList>
              
              <TabsContent value="businesses" className="mt-6">
                <BusinessTable />
              </TabsContent>
              
              <TabsContent value="articles" className="mt-6">
                <ArticleAnalytics />
              </TabsContent>
              
              <TabsContent value="resources" className="mt-6">
                <ResourceManagement />
              </TabsContent>

              <TabsContent value="billing" className="mt-6">
                <ClientBillingManager />
              </TabsContent>
            </Tabs>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default AdminPanel;