import { supabase } from '@/integrations/supabase/client';

export const authOperations = {
  signIn: async (email: string, password: string) => {
    console.log('Attempting sign in for:', email);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error('Sign in error:', error);
      throw error;
    }

    console.log('Sign in successful');
    return data;
  },

  signUp: async (email: string, password: string, metadata?: any) => {
    console.log('Attempting sign up for:', email);
    
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: metadata,
      },
    });
    
    if (error) throw error;
    
    console.log('Sign up successful:', data);
    return data;
  },

  signOut: async () => {
    console.log('Attempting sign out');
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    console.log('Sign out successful');
  }
};