import { supabase } from "@/integrations/supabase/client";

interface AuthorizeNetConfig {
  apiLoginId: string;
  transactionKey: string;
  clientKey: string;
}

interface BillingInfo {
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber?: string;
}

export const getAuthorizeNetConfig = async (): Promise<AuthorizeNetConfig> => {
  console.log('Fetching Authorize.net sandbox configuration...');
  
  const { data, error } = await supabase.functions.invoke('authorize-config');

  if (error) {
    console.error('Error fetching Authorize.net configuration:', error);
    throw new Error('Failed to retrieve payment gateway configuration');
  }

  if (!data || !data.apiLoginId || !data.transactionKey) {
    console.error('Invalid Authorize.net configuration received:', data);
    throw new Error('Payment gateway configuration is incomplete');
  }

  return {
    apiLoginId: data.apiLoginId,
    transactionKey: data.transactionKey,
    clientKey: data.clientKey || ''
  };
};

export const createSubscription = async (
  cardData: {
    cardNumber: string;
    expirationDate: string;
    cardCode: string;
  },
  billingInfo: BillingInfo,
  amount: number,
  packageType: string
) => {
  console.log('Starting subscription creation process in sandbox environment...');
  const config = await getAuthorizeNetConfig();
  
  const subscriptionData = {
    ARBCreateSubscriptionRequest: {
      merchantAuthentication: {
        name: config.apiLoginId,
        transactionKey: config.transactionKey
      },
      refId: `sub_${Date.now()}`,
      subscription: {
        name: `Monthly Subscription for ${packageType}`,
        paymentSchedule: {
          interval: {
            length: "1",
            unit: "months"
          },
          startDate: new Date().toISOString().split('T')[0],
          totalOccurrences: "9999",
          trialOccurrences: "0"
        },
        amount: amount.toFixed(2),
        trialAmount: "0.00",
        payment: {
          creditCard: {
            cardNumber: cardData.cardNumber.replace(/\s/g, ''),
            expirationDate: cardData.expirationDate.replace('/', ''),
            cardCode: cardData.cardCode
          }
        },
        customer: {
          type: "individual",
          id: `cust_${Date.now()}`,
          email: billingInfo.email,
          phoneNumber: billingInfo.phoneNumber || ''
        },
        billTo: {
          firstName: billingInfo.firstName,
          lastName: billingInfo.lastName,
          address: billingInfo.street,
          city: billingInfo.city,
          state: billingInfo.state,
          zip: billingInfo.zipCode,
          country: "US"
        }
      }
    }
  };

  console.log('Subscription request payload (excluding sensitive data):', {
    ...subscriptionData,
    ARBCreateSubscriptionRequest: {
      ...subscriptionData.ARBCreateSubscriptionRequest,
      merchantAuthentication: '***REDACTED***',
      subscription: {
        ...subscriptionData.ARBCreateSubscriptionRequest.subscription,
        payment: '***REDACTED***'
      }
    }
  });

  try {
    const response = await fetch('https://apitest.authorize.net/xml/v1/request.api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(subscriptionData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Authorize.net API error response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Authorize.net API response:', {
      resultCode: result.messages?.resultCode,
      messageCode: result.messages?.message?.[0]?.code,
      messageText: result.messages?.message?.[0]?.text,
      subscriptionId: result.subscriptionId
    });
    
    if (result.messages?.resultCode !== "Ok") {
      console.error('Subscription creation failed:', result.messages?.message?.[0]);
      throw new Error(result.messages?.message?.[0]?.text || 'Subscription creation failed');
    }

    console.log('Subscription created successfully in sandbox');
    return {
      success: true,
      subscriptionId: result.subscriptionId
    };
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};