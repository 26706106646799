import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export const usePartnerCode = (amount: number) => {
  const [partnerCode, setPartnerCode] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState<number | null>(null);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const { toast } = useToast();

  const validatePartnerCode = async (code: string) => {
    if (!code) return;
    
    setIsValidatingCode(true);
    try {
      const { data, error } = await supabase
        .from('partner_codes')
        .select('discount_percentage')
        .ilike('code', code)
        .maybeSingle();

      if (error) {
        console.error('Error validating partner code:', error);
        toast({
          title: "Error",
          description: "Failed to validate partner code. Please try again.",
          variant: "destructive"
        });
        return;
      }

      if (data) {
        const discounted = Number((amount * (1 - data.discount_percentage / 100)).toFixed(2));
        setDiscountedAmount(discounted);
        toast({
          title: "Partner Code Applied",
          description: `A ${data.discount_percentage}% discount has been applied. Your new monthly total is $${discounted}`,
          variant: "default"
        });
      } else {
        setDiscountedAmount(null);
        toast({
          title: "Invalid Partner Code",
          description: "The partner code you entered is not valid. Please check and try again.",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error('Error validating partner code:', error);
      setDiscountedAmount(null);
      toast({
        title: "Error",
        description: "There was a problem validating your partner code. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsValidatingCode(false);
    }
  };

  const handlePartnerCodeChange = (code: string) => {
    setPartnerCode(code.toUpperCase());
  };

  return {
    partnerCode,
    discountedAmount,
    isValidatingCode,
    handlePartnerCodeChange,
    validatePartnerCode
  };
};