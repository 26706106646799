import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";
import { WooboundLogo } from "@/components/shared/WooboundLogo";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

const GoogleConnectPage = () => {
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();

  const handleGoogleConnect = () => {
    window.open('https://woobound.digitalsero.app/clients/onboarding/c50a97dd-5503-4c9b-89a3-1f8c795a0b95', '_blank');
    setIsConnected(true);
  };

  const handleComplete = async (connected: boolean) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error("No authenticated user found");

      const { error: sessionError } = await supabase
        .from('onboarding_sessions')
        .update({ 
          google_business_connected: connected,
          completed: true 
        })
        .eq('user_id', user.id)
        .single();

      if (sessionError) throw sessionError;

      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error completing onboarding:', error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-2xl space-y-8">
        <WooboundLogo />
        
        <div className="space-y-6">
          <h2 className="text-xl font-semibold text-[#333333] mb-4">Connect Google Business Profile</h2>
          
          <div className="space-y-4">
            <Button 
              onClick={handleGoogleConnect}
              className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90 flex items-center justify-center"
            >
              Connect Google Business Profile
              <ExternalLink className="ml-2 h-4 w-4" />
            </Button>

            {isConnected && (
              <Button 
                onClick={() => handleComplete(true)}
                className="w-full bg-[#e0933c] hover:bg-[#e0933c]/90"
              >
                Done
              </Button>
            )}

            <Button 
              variant="outline"
              onClick={() => handleComplete(false)}
              className="w-full"
            >
              Skip for now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleConnectPage;