export const businessprofile = {
   title: "Business Profile",
  subtitle: "Reach 100% for maximum optimization.",
  form: {
    businessName: "Business Name",
    businessEmail: "Business Email",
    websiteUrl: "Website URL",
    phoneNumber: "Phone Number",
    category: "Category",
    openingDate: "Opening Date",
    openingHours: "Opening Hours",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "ZIP Code",
    shortDescription: "Brief description of your business",
    longDescription: "Detailed description of your business",
    facebookUrl: "Facebook URL",
    instagramUrl: "Instagram URL",
    businessServices: "Enter services separated by commas",
    seoCampaignGoal: "SEO Campaign Goal",
    desiredKeywords: "Desired Keywords",
    keywordsPlaceholder: "Enter keywords separated by commas",
    paymentMethods: "Payment Methods",
    paymentMethodsPlaceholder: "Enter payment methods separated by commas",
    promotions: "Promotions",
    promotionsPlaceholder: "Enter your promotions",
  },
};
