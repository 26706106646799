import { useState, useRef, useEffect } from 'react';
import { useBusinessSearch } from '@/hooks/useBusinessSearch';
import { Loader2, Building2 } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { BusinessDetails } from './types';

interface BusinessSearchInputProps {
  onBusinessSelect: (details: BusinessDetails) => void;
}

export const BusinessSearchInput = ({ onBusinessSelect }: BusinessSearchInputProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {
    searchQuery,
    setSearchQuery,
    predictions,
    isLoading,
    handleSearch,
    getPlaceDetails
  } = useBusinessSearch();

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePlaceSelect = async (placeId: string) => {
    const details = await getPlaceDetails(placeId);
    if (details) {
      onBusinessSelect(details);
      setShowDropdown(false);
    }
  };

  return (
    <div className="relative w-full" ref={ref}>
      <div className="relative">
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <Building2 className="h-4 w-4 text-gray-500" />
        </div>
        <Input
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setShowDropdown(!!e.target.value);
          }}
          placeholder="Search for your business..."
          className="pl-9 pr-4"
        />
        {isLoading && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
          </div>
        )}
      </div>

      {showDropdown && searchQuery.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
          <div className="max-h-60 overflow-auto py-1">
            {isLoading ? (
              <div className="px-4 py-2 text-sm text-gray-500">
                Searching...
              </div>
            ) : predictions.length > 0 ? (
              predictions.map((prediction) => (
                <button
                  key={prediction.place_id}
                  onClick={() => handlePlaceSelect(prediction.place_id)}
                  className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                >
                  <div className="font-medium">
                    {prediction.structured_formatting.main_text}
                  </div>
                  <div className="text-sm text-gray-500">
                    {prediction.structured_formatting.secondary_text}
                  </div>
                </button>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};