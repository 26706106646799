import { useQuery } from "@tanstack/react-query";
import { useParams, Link, useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, CheckCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { AspectRatio } from "@/components/ui/aspect-ratio";

const CourseLesson = () => {
  const { courseId, lessonId } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: lesson, isLoading: lessonLoading } = useQuery({
    queryKey: ['academy_lesson', lessonId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('academy_lessons')
        .select(`
          *,
          academy_modules!inner (
            id,
            title,
            course_id,
            order_number,
            academy_courses!inner (
              id,
              title
            )
          )
        `)
        .eq('id', lessonId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: progress, isLoading: progressLoading } = useQuery({
    queryKey: ['academy_lesson_progress', lessonId],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from('academy_lesson_progress')
        .select('*')
        .eq('lesson_id', lessonId)
        .eq('user_id', session.user.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
  });

  const { data: siblings } = useQuery({
    queryKey: ['academy_lesson_siblings', lessonId],
    queryFn: async () => {
      if (!lesson?.academy_modules?.id) return null;

      const { data, error } = await supabase
        .from('academy_lessons')
        .select('id, title')
        .eq('module_id', lesson.academy_modules.id)
        .order('order_number');

      if (error) throw error;
      return data;
    },
    enabled: !!lesson?.academy_modules?.id,
  });

  const handleComplete = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return;

      if (progress) {
        const { error } = await supabase
          .from('academy_lesson_progress')
          .update({
            completed: true,
            completed_at: new Date().toISOString(),
            progress_percent: 100
          })
          .eq('id', progress.id);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('academy_lesson_progress')
          .insert({
            lesson_id: lessonId,
            user_id: session.user.id,
            completed: true,
            completed_at: new Date().toISOString(),
            progress_percent: 100
          });

        if (error) throw error;
      }

      toast({
        title: "Lesson Completed",
        description: "Your progress has been saved.",
      });

      // Navigate to next lesson if available
      if (siblings) {
        const currentIndex = siblings.findIndex(s => s.id === lessonId);
        if (currentIndex < siblings.length - 1) {
          navigate(`/academy/courses/${courseId}/lessons/${siblings[currentIndex + 1].id}`);
        }
      }
    } catch (error) {
      console.error('Error updating progress:', error);
      toast({
        title: "Error",
        description: "Failed to update progress. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (lessonLoading || progressLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen w-full bg-gray-50">
          <AppSidebar />
          <div className="flex-1 p-4 md:p-8">
            <div className="animate-pulse">
              <div className="h-8 w-64 bg-gray-200 rounded mb-4"></div>
              <div className="h-4 w-96 bg-gray-200 rounded mb-8"></div>
            </div>
          </div>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-gray-50">
        <AppSidebar />
        <div className="flex-1">
          <main className="p-4 md:p-8">
            <div className="max-w-6xl mx-auto space-y-8">
              <div>
                <Button
                  variant="ghost"
                  className="mb-4"
                  asChild
                >
                  <Link to={`/academy/courses/${courseId}`} className="flex items-center gap-2">
                    <ChevronLeft className="h-4 w-4" />
                    Back to Course
                  </Link>
                </Button>

                <h1 className="text-2xl font-semibold text-gray-900 mb-2">{lesson?.title}</h1>
                <p className="text-sm text-gray-500">
                  {lesson?.academy_modules?.academy_courses?.title} • {lesson?.academy_modules?.title}
                </p>
              </div>

              <div className="grid gap-8 md:grid-cols-3">
                <div className="md:col-span-2 space-y-6">
                  {lesson?.video_url && (
                    <Card className="overflow-hidden">
                      <AspectRatio ratio={16 / 9}>
                        <iframe
                          src={lesson.video_url}
                          title={lesson.title}
                          className="w-full h-full"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </AspectRatio>
                    </Card>
                  )}

                  {lesson?.content && (
                    <Card className="p-6 prose max-w-none">
                      <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
                    </Card>
                  )}

                  <div className="flex justify-between items-center">
                    {siblings && (
                      <>
                        {siblings.findIndex(s => s.id === lessonId) > 0 && (
                          <Button
                            variant="outline"
                            onClick={() => {
                              const currentIndex = siblings.findIndex(s => s.id === lessonId);
                              navigate(`/academy/courses/${courseId}/lessons/${siblings[currentIndex - 1].id}`);
                            }}
                          >
                            <ChevronLeft className="h-4 w-4 mr-2" />
                            Previous Lesson
                          </Button>
                        )}
                        
                        {siblings.findIndex(s => s.id === lessonId) < siblings.length - 1 ? (
                          <Button
                            onClick={handleComplete}
                          >
                            Complete & Continue
                            <ChevronRight className="h-4 w-4 ml-2" />
                          </Button>
                        ) : (
                          <Button
                            onClick={handleComplete}
                          >
                            Complete Lesson
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <Card className="p-6 h-fit">
                  <h2 className="font-semibold text-lg mb-4">Lesson Progress</h2>
                  {progress?.completed ? (
                    <div className="text-green-500 flex items-center gap-2">
                      <CheckCircle className="h-5 w-5" />
                      <span>Completed</span>
                    </div>
                  ) : (
                    <p className="text-gray-500">
                      Complete this lesson to track your progress.
                    </p>
                  )}
                </Card>
              </div>
            </div>
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default CourseLesson;
