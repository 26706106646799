import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Info } from "lucide-react";

export function TrendsGuide() {
  return (
    <Alert>
      <Info className="h-4 w-4" />
      <AlertTitle>How to Read This Data</AlertTitle>
      <AlertDescription>
        <p>These charts show the relative interest in your business category over time:</p>
        <ul className="list-disc list-inside mt-2 space-y-1">
          <li>A value of 100 represents peak popularity for the term</li>
          <li>A value of 50 means the term is half as popular</li>
          <li>A score of 0 means insufficient data</li>
          <li>Compare national and state trends to identify local opportunities</li>
        </ul>
      </AlertDescription>
    </Alert>
  );
}