import { FilterOptions } from "./types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar as CalendarIcon } from "lucide-react";

interface BusinessFiltersProps {
  filterOptions: FilterOptions;
  onFilterChange: (options: FilterOptions) => void;
}

export const BusinessFilters = ({
  filterOptions,
  onFilterChange,
}: BusinessFiltersProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <Select
        value={filterOptions.completionStatus}
        onValueChange={(value: 'all' | 'complete' | 'incomplete') => 
          onFilterChange({ ...filterOptions, completionStatus: value })
        }
      >
        <SelectTrigger className="w-full md:w-[200px]">
          <SelectValue placeholder="Profile Status" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">All Profiles</SelectItem>
          <SelectItem value="complete">Complete</SelectItem>
          <SelectItem value="incomplete">Incomplete</SelectItem>
        </SelectContent>
      </Select>

      <Select
        value={filterOptions.category}
        onValueChange={(value) => 
          onFilterChange({ ...filterOptions, category: value })
        }
      >
        <SelectTrigger className="w-full md:w-[200px]">
          <SelectValue placeholder="Category" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">All Categories</SelectItem>
          <SelectItem value="restaurant">Restaurant</SelectItem>
          <SelectItem value="retail">Retail</SelectItem>
          <SelectItem value="service">Service</SelectItem>
        </SelectContent>
      </Select>

      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="w-full md:w-auto">
            <CalendarIcon className="mr-2 h-4 w-4" />
            Date Range
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="range"
            selected={{
              from: filterOptions.dateRange.from || undefined,
              to: filterOptions.dateRange.to || undefined,
            }}
            onSelect={(range) => 
              onFilterChange({
                ...filterOptions,
                dateRange: {
                  from: range?.from || null,
                  to: range?.to || null,
                },
              })
            }
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};