import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { BillingTabs } from "./billing/BillingTabs";

export const ClientBillingManager = () => {
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("active");

  const { data: subscriptionData, isLoading: isLoadingSubscriptions } = useQuery({
    queryKey: ["admin-subscriptions"],
    queryFn: async () => {
      console.log('Fetching subscriptions...');
      
      // First get Supabase subscriptions
      const { data: supabaseSubscriptions, error: supabaseError } = await supabase
        .from("subscriptions")
        .select(`
          *,
          profiles:profiles(email, first_name, last_name),
          payment_transactions(*)
        `)
        .order("created_at", { ascending: false });

      if (supabaseError) {
        console.error('Supabase error:', supabaseError);
        toast({
          title: "Error",
          description: "Failed to fetch subscriptions from database",
          variant: "destructive",
        });
        throw supabaseError;
      }

      console.log('Supabase subscriptions:', supabaseSubscriptions);

      // Then get Authorize.net data
      const { data: authorizeData, error: authorizeError } = await supabase.functions.invoke('authorize-subscriptions');
      
      if (authorizeError) {
        console.error('Authorize.net error:', authorizeError);
        toast({
          title: "Warning",
          description: "Failed to fetch Authorize.net data",
          variant: "destructive",
        });
      }

      console.log('Authorize.net data:', authorizeData);

      // Combine the data
      const enrichedSubscriptions = supabaseSubscriptions.map(sub => {
        const authorizeSubscription = authorizeData?.subscriptions?.find(
          authSub => authSub.id === sub.external_subscription_id
        );
        
        const hasExpiringCard = authorizeData?.expiringCards?.some(
          card => card.id === sub.external_subscription_id
        );

        const hasDeclinedTransaction = authorizeData?.declinedTransactions?.some(
          tx => tx.subscriptionId === sub.external_subscription_id
        );

        return {
          ...sub,
          authorize_status: authorizeSubscription?.status || 'unknown',
          has_expiring_card: hasExpiringCard || false,
          has_declined_transaction: hasDeclinedTransaction || false
        };
      });

      console.log('Enriched subscriptions:', enrichedSubscriptions);
      return enrichedSubscriptions;
    },
  });

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h2 className="text-2xl font-bold mb-6">Client Billing Management</h2>
        <BillingTabs
          activeTab={activeTab}
          onTabChange={setActiveTab}
          subscriptionData={subscriptionData || []}
          isLoadingSubscriptions={isLoadingSubscriptions}
        />
      </Card>
    </div>
  );
};