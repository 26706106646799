import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { CreditCard, Package } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { PaymentForm } from "./PaymentForm";

export const BillingOverview = () => {
  const { t } = useTranslation();
  
  const { data: subscription, refetch } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data, error } = await supabase
        .from('subscriptions')
        .select(`
          *,
          payment_methods!payment_method_id(
            card_type,
            last_four
          )
        `)
        .eq('user_id', user.id)
        .eq('status', 'active')
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    }
  });

  const { data: paymentHistory } = useQuery({
    queryKey: ['payment_history'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { data, error } = await supabase
        .from('payment_history')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    }
  });

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Card className="p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-3 rounded-full bg-primary/10">
          <Package className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{t('billing.currentPlan.title')}</h2>
          <p className="text-sm text-gray-500">{t('billing.currentPlan.subtitle')}</p>
        </div>
      </div>

      {subscription ? (
        <div className="space-y-4">
          <div className="flex justify-between items-center pb-4 border-b border-gray-100">
            <div>
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.package')}</p>
              <p className="text-lg font-semibold text-gray-900">
                {t(`billing.packages.${subscription.package_type}`)}
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.monthlyPrice')}</p>
              <p className="text-lg font-semibold text-primary">${subscription.price}</p>
            </div>
          </div>

          <div className="flex justify-between items-center pb-4 border-b border-gray-100">
            <div>
              <p className="text-sm font-medium text-gray-700">{t('billing.currentPlan.nextBillingDate')}</p>
              <p className="text-gray-900">
                {subscription.next_billing_date ? formatDate(subscription.next_billing_date) : 'N/A'}
              </p>
            </div>
            {subscription.payment_methods && (
              <div className="flex items-center gap-2">
                <CreditCard className="h-5 w-5 text-gray-400" />
                <span className="text-gray-600">
                  {subscription.payment_methods.card_type} ending in {subscription.payment_methods.last_four}
                </span>
              </div>
            )}
          </div>

          {paymentHistory && paymentHistory.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">{t('billing.paymentHistory.title')}</h3>
              <div className="space-y-3">
                {paymentHistory.map((payment) => (
                  <div key={payment.id} className="flex justify-between items-center py-2">
                    <div>
                      <p className="text-sm text-gray-600">{formatDate(payment.created_at)}</p>
                      <p className="text-sm text-gray-500">Card ending in {payment.card_last_four}</p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">${payment.amount}</p>
                      <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800">
                        {payment.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-6">
          <p className="text-gray-500 text-center">{t('billing.currentPlan.noSubscription')}</p>
          <PaymentForm
            packageType="maps_seo"
            amount={598}
            onSuccess={refetch}
          />
        </div>
      )}
    </Card>
  );
};